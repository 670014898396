import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import TruckIncomeSummary from "./TruckIncomSummary/TruckIncomeSummary"
import JobPlaning from "./JobPlaning/JobPlaning"


export const branchApiNm = {
  getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
  getTruckIncomeSummary: "/branch/getTruckIncomeSummary"
}

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobOrderAllDetail", num: "8.2", menuName: extraPageName.JobOrderAllDetail, prNum: 8,  permission: "EXE;FIN" },
  { page: "TruckIncomeSummary", num: "8.3", menuName: extraPageName.TruckIncomeSummary, prNum: 8,  permission: "EXE;FIN" },
]

export const extraRoute = (path)=> [
  <Route key={"JobOrderAllDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"TruckIncomeSummary"} path={`${path}/TruckIncomeSummary`} ><TruckIncomeSummary /> </Route>,
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
]

export const extraMainRoute = (path)=> []
