import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DataBox from './DataBox';
import { GroupRounded, RemoveCircleRounded } from '@mui/icons-material';
import { locationText } from 'utils/locationText';
import { jobOrderColNm } from 'constants/jobOrderConstant';
import { dailyExpenseColNm } from 'constants/dailyExpenseConstant';
import { numberFormat } from 'utils';

export const jobColumns = (handleSelect, disableArrow) => [
  {
    field: 'JobSNm',
    headerName: 'งาน',
    width: 100,
  },
  {
    field: 'JobNo',
    headerName: 'JobNo',
    flex: 1,
    renderCell: (params) => {
      //make rtl text in div with textoverflow ellipsis
      return <div style={{ direction: "rtl", overflow: "hidden", textOverflow: "ellipsis" }}>{params.value}</div>
    }
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 70,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => locationText(params.row),
  },
  {
    field: 'AptTm',
    headerName: "เวลานัด",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'RtnDteEn',
    headerName: "Closing",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'IsRdyTake',
    headerName: 'เช็ค',
    width: 45,
    renderCell: (params) => {
      return params.value === 1 ? <CheckCircleIcon color="success" /> : <CancelIcon color="action" />
    },
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 60,
    align: "center"
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    hide: !handleSelect,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        disabled={disableArrow || params.row.disableArrow}
        icon={<ArrowCircleRightIcon color={disableArrow || params.row.disableArrow ? "inherit" : "primary"} sx={{ fontSize: 30 }} />}
        label="Select"
        onClick={() => handleSelect(params.row)}
      />
    ],
  }
]

export const summaryColumns = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: 'TagNm',
    headerName: 'Tag',
    width: 100,
  },
  {
    field: 'Bkg',
    headerName: 'Booking',
    width: 120,
  },
  {
    field: 'Agnt',
    headerName: 'Agent',
    width: 100,
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 80,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => `${params.row.TakePlc || ""}${params.row.Loc === '-' ? " - " : `-${params.row.Loc}-`}${params.row.RtnPlc || ""}`,
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 80,
    align: "center"
  },
]

export const driverColumns = (handleRemoveJobOrder, handleBkgClick, handleJobOrdIdClick, filterData, selectionPrint, setSelectionPrint, showCardDetail) => {
  const handles = { handleRemoveJobOrder, handleBkgClick, handleJobOrdIdClick, filterData, selectionPrint, setSelectionPrint, showCardDetail }
  return [
    {
      field: 'NName',
      headerName: 'พขร.',
      width: 130,
      valueGetter: (params) => ` ${params.row.NName}`,
    },
    {
      field: 'Batt',
      headerName: 'Battery',
      width: 60,
    },
    {
      field: 'yd',
      headerName: 'วันก่อน',
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => params.row.yd.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.yd} handles={handles} />,
    },
    {
      field: 'td',
      headerName: 'วันนี้',
      flex: 1,
      minWidth: 180,
      cellClassName: "today",
      valueGetter: (params) => params.row.td.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.td} handles={handles} />,
    },
    {
      field: 'tm',
      headerName: 'พรุ่งนี้',
      flex: 1,
      minWidth: 180,
      cellClassName: "tomorrow",
      valueGetter: (params) => params.row.tm.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.tm} handles={handles} />,
    },
    {
      field: 'atm',
      headerName: 'วันหน้า',
      flex: 1,
      minWidth: 180,
      valueGetter: (params) => params.row.atm.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.atm} handles={handles} />,
    },
  ]
}

export const printColumnHeader = (msData) => [
  {
    id: "NName",
    name: "NName",
    prompt: "พขร",
    width: 1.9,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BL",
    name: "BL",
    prompt: msData.tags.find(tag => tag.TagId === 1)?.Name,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BB",
    name: "BB",
    prompt: `${msData.tags.find(tag => tag.TagId === 2)?.Name},${msData.tags.find(tag => tag.TagId === 5)?.Name}`,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "LB",
    name: "LB",
    prompt: msData.tags.find(tag => tag.TagId === 3)?.Name,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "DX",
    name: "DX",
    prompt: "เงิน",
    width: 0.95,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Toll",
    name: "Toll",
    prompt: "ทางด่วน",
    width: 0.95,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
]

export const printJobColumnHeader = [
  {
    id: "Tag",
    name: "Tag",
    prompt: "Tag",
    width: 1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Bkg",
    name: "Bkg",
    prompt: "Booking",
    width: 1.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Agnt",
    name: "Agnt",
    prompt: "Agent",
    width: 0.8,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContSizeFull",
    name: "ContSizeFull",
    prompt: "ขนาดตู้",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteSt",
    name: "TakeDteSt",
    prompt: "First",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteEn",
    name: "TakeDteEn",
    prompt: "Last",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "IsRdyTake",
    name: "IsRdyTake",
    prompt: "พร้อมรับ",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Count",
    name: "Count",
    prompt: "จำนวน(เที่ยว)",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
]

export const driverStatisticColumns = [
  {
    field: 'Header',
    headerName: 'รายการ',
    width: 130,
  },
  {
    field: 'Num',
    headerName: 'จำนวน',
    flex: 1,
  },
  {
    field: 'Avg',
    headerName: 'เฉลี่ย',
    flex: 1,
  },
  {
    field: 'Min',
    headerName: 'Min',
    flex: 1,
  },
  {
    field: 'Max',
    headerName: 'Max',
    flex: 1,
  },
  {
    field: 'No',
    headerName: 'ลำดับที่',
    flex: 1,
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<GroupRounded />}
        label="Select"
        onClick={() => { }}
      />
    ],
  }
]

export const jobOrderColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 70,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  }
]
export const expenseColumns = (handleRemoveRow) => {
  const msData = JSON.parse(localStorage.getItem("msData"))
  return [
    {
      field: "ExpDte",
      headerName: dailyExpenseColNm.ExpDte,
      width: 100,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    },
    {
      field: "ExpId",
      headerName: "ค่าใช้จ่าย",
      valueGetter: (params) => msData.expObj[params.value]?.ExpNm || "-",
      flex: 1
    },
    {
      field: "Amnt",
      headerName: dailyExpenseColNm.Amnt,
      width: 80,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(parseFloat(params.value)) : "0",
    },
    {
      field: "SumDalyExpDocAmnt",
      headerName: "ยอดรวมเอกสาร",
      width: 110,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    },
    {
      field: "ChngeAmnt",
      headerName: "เงินทอน/เกิน",
      width: 90,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    },
    {
      field: "ChngClrTm",
      headerName: "Clearเงินทอน",
      width: 100,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveCircleRounded />}
          label="Clear"
          onClick={() => handleRemoveRow(params.row)}
        />
      ]
    }
  ]
}