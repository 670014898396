import dayjs from "dayjs"

export const clearFilterData = {
  AptTmSt: null,
  AptTmEn: null,
  CusId: null,
  JobId: null
}

export const initFilterData = {
  ...clearFilterData,
  AptTmSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  AptTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
}