export const repairOrderApiNm = {
  getRepairOrder: "/repair-order/getRepairOrder",
  getRepairOrderDetail: "/repair-order/getRepairOrderDetail",
  insertRepairOrder: "/repair-order/insertRepairOrder",
  updateRepairOrder: "/repair-order/updateRepairOrder",
  deleteRepairOrder: "/repair-order/deleteRepairOrder",
  uploadFile: "/repair-order/uploadFile",
  deleteFile: "/repair-order/deleteFile",
}

export const repairOrderColNm = {
  ROTm: "เวลาแจ้งซ่อม",
  ROStsId: "สถานะการซ่อม",
  ROStsNm: "สถานะการซ่อม",
  DrvId: "พนักงานขับรถ",
  DrvNNm: "พนักงานขับรถ",
  FLNNm:  "พนักงานขับรถ",
  TukId: "เลขรถ",
  TukCd: "เลขรถ",
  OrdHdr: "หัวข้อแจ้งซ่อม",
  OrdDscp: "รายละเอียดแจ้งซ่อม",
  ReprDscp: "รายละเอียดการซ่อม",
  StDte: "วันที่เริ่มซ่อม",
  EstFinDte: "วันที่คาดว่าเสร็จ",
  FinDte: "วันที่เสร็จ",
}