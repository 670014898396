import { Box, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { maintenanceApiNm } from "./constant"
import { addIdForDataGrid } from "utils"
import { planTruckColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"


const LinkPlanDialog = ({ mtChkPlnId, title, dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  // const [filterData, setFilterData] = useState({})
  const [selectionModel, setSelectionModel] = useState([])

  const handleSave = useCallback(() => {
    const postData = {
      MtChkPlnId: mtChkPlnId,
      argsArr: selectionModel.map(tukId => ({ MtChkPlnId: mtChkPlnId, TukId: tukId }))
    }
    ax.post(maintenanceApiNm.insertCheckupPlanTruckBulk, postData).then(value=>{
      if(value.data){
        setDialogOpen(false)
      }
    })
  }, [ax, selectionModel, mtChkPlnId, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(maintenanceApiNm.getCheckUpPlanTruck, { MtChkPlnId: mtChkPlnId }).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "TukId"))
          setSelectionModel(value.data.filter(tuk=>tuk.HasPlan).map(tuk => tuk.TukId))
        }
      })
    } else {
      setSelectionModel([])
    }
  }, [dialogOpen, ax, mtChkPlnId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="แบบฟอร์มตรวจเช็ค-รถ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} sx={{ gap: 0 }} >
          <BoxFR sx={{alignItems: "flex-end"}}>
            <Typography variant="h6" gutterBottom>รถที่ใช้แบบฟอร์ม: <strong>{title}</strong> </Typography>
            <Box flexGrow={1} />
            <Typography  gutterBottom>จำนวน: {selectionModel.length} คัน</Typography>
          </BoxFR>
          <Box height={600} >
            <DataGridCellExpand
              density="compact"
              rows={dataTable}
              columns={planTruckColumns}
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectionModel}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              hideFooter
            />
          </Box>
          {/* <SaveButton>บันทึกรถที่เลือก</SaveButton> */}
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึกรถที่เลือก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(LinkPlanDialog)