
import { Button } from "@mui/material";
import { numberFormat } from "utils";

export const columns = (handleOpenDailyJobDialog) => [
  {
    field: "No",
    headerName: "No",
    width: 50,
  },
  {
    field: "Model",
    headerName: "Model",
    width: 80,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    width: 80,
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนรถ",
    width: 80,
  },
  {
    field: "DrvNm",
    headerName: "พขร.",
    minWidth: 120,
    flex: 1
  },
  {
    field: "TukRmk",
    headerName: "หมายเหตุรถ",
    minWidth: 120,
    flex: 1
  },
  {
    field: "Dscp",
    headerName: "หมายเหตุ พขร",
    minWidth: 120,
    flex: 1
  },
  {
    field: "NumJob",
    headerName: "จำนวนเที่ยว",
    valueFormatter: (params) => `${params.value}`,
    width: 90,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 100,
    renderCell: (params)=> params.value? 
    <Button onClick={()=>{handleOpenDailyJobDialog(params.row.JobOrdId)}}>{params.value}</Button>:""
  },
  {
    field: "CusSNm",
    headerName: "ลูกค้า",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "Size",
    width: 80,
  },
  {
    field: "ShpmTypNm",
    headerName: "ประเภทงาน",
    width: 80,
  },
  {
    field: "TakePlc",
    headerName: "รับตู้",
    width: 100,
  },
  {
    field: "Loc",
    headerName: "โรงงาน",
    width: 150,
  },
  {
    field: "RtnPlc",
    headerName: "คืนตู้",
    width: 100,
  },
  {
    field: "TrnsUntPr",
    headerName: "ค่าขนส่ง",
    width: 120,
    valueGetter: (params) => params.value ? +params.value : 0,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "SumTrnsUntPr",
    headerName: "รวมค่าขนส่ง",
    valueGetter: (params) => params.value ? +params.value : 0,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
    width: 120,
  },
  {
    field: "AvgTrnsUntPr",
    headerName: "เฉลี่ยค่าขนส่ง",
    valueGetter: (params) => params.value ? +params.value : 0,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
    width: 120,
  },
]

export const printColumns = [
    {
      field: "No",
      headerName: "No",
      width: 0.3,
      align: "center",
    },
    {
      field: "Model",
      headerName: "Model",
      width: 0.6,
      align: "center",
    },
    {
      field: "TukCd",
      headerName: "เลขรถ",
      width: 0.7,
    },
    {
      field: "LicNm",
      headerName: "ทะเบียนรถ",
      width: 0.7,
    },
    {
      field: "DrvNm",
      headerName: "พขร.",
      width: 1.2,
    },
    {
      field: "TukRmk",
      headerName: "หมายเหตุ",
      width: 1,
    },
    {
      field: "NumJob",
      headerName: "Qty",
      valueFormatter: (params) => `${params.value}`,
      width: 0.35,
      align: "center",
    },
    {
      field: "CusSNm",
      headerName: "ลูกค้า",
      width: 0.8,
      align: "center"
    },
    {
      field: "ContSizeFull",
      headerName: "Size",
      width: 0.45,
      align: "center"
    },
    {
      field: "ShpmTypNm",
      headerName: "ประเภทงาน",
      width: 0.8,
      align: "center"
    },
    {
      field: "Loc",
      headerName: "สถานที่",
      width: 1.8,
    },
    {
      field: "TrnsUntPr",
      headerName: "ค่าขนส่ง",
      width: 0.8,
      align: "right",
    },
    {
      field: "SumTrnsUntPr",
      headerName: "รวมค่าขนส่ง",
      align: "right",
      width: 0.8,
    },
    {
      field: "AvgTrnsUntPr",
      headerName: "เฉลี่ยค่าขนส่ง",
      align: "right",
      width: 0.8,
    },
]