import React, { useCallback, useState } from "react";
import { InputAdornment, IconButton, Box, TextField, Button, Popover, List, ListItem, Typography } from "@mui/material"
import ArrowDropDownCircleRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { alertError } from "../Alert";
import isFileOverSize from "utils/isFileOverSize";
import { BoxFC } from "components/BoxCustom";
import { SystemUpdateAltRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
const FileUpload = ({ options, fileUploadData, setFileUploadData, width, height, sx }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [fileName, setFileName] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)

  const handleAddFile = useCallback((file, isMulti) => {
    console.log("in handleAddFile fileName::", fileName)
    if (fileName === "" && !isMulti) {
      alertError("กรุณาใส่ชื่อไฟล์ ก่อน");
      return;
    }
    setFileUploadData(oldState => {
      console.log("handleAddFile oldState::", oldState)
      console.log("handleAddFile oldState.addedFiles::", oldState.addedFiles)
      return ({
        ...oldState,
        addedFiles: [
          ...oldState.addedFiles,
          {
            file: file,
            fileNm: isMulti?file.name: fileName,
            label: isMulti?file.name: fileName,
            isEdit: false,
            imgLink: URL.createObjectURL(file)
          }
        ]
      })
    });
    setSelectedFile(null);
    setFileName("");
  }, [fileName, setFileUploadData])

  const handleListItemClick = (item) => {
    setFileName(item);
    setAnchorEl(null);
  }

  const handleDrop = useCallback((e) => {
    e.preventDefault()
    console.log("drop", e)
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile();
        if (isFileOverSize(file)) return
        console.log('... file[0].name = ' + file.name);
        console.log("file::", file)
        setSelectedFile(file)
        const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
        setFileName(fileNameOnly)
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0]
        if (isFileOverSize(file)) return
        setSelectedFile(file)
        const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
        setFileName(fileNameOnly)
      }
    }
  }, [])
  const handleDragOver = useCallback((e) => {
    /**Must prevent then can use onDrop 
     * https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
     */
    e.preventDefault()
  }, [])

  const handleDropMulti = useCallback( (e) =>{
    e.preventDefault()
    let files = []
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        files = Array.from(e.dataTransfer.items).map(item => item.getAsFile())
      } else {
        return
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        files = Array.from(e.dataTransfer.files)
      } else {
        return
      }
    }

    for (const file of files) {
      if (isFileOverSize(file)) return
      handleAddFile(file, true)
    }
    
  }, [handleAddFile])
  const handleSelectFile = useCallback((e) => {
    const file = e.target.files[0]
    if (isFileOverSize(file)) return

    setSelectedFile(file)
    setFileName(file.name)
  }, [])
  console.log("fileUploadData::", fileUploadData)
  return (

    <Box display="flex" flexDirection="column" gap={1} width={width || "100%"} height={height || "100%"} alignItems="flex-start" sx={sx} >
      <Box display="flex" flexDirection="row" gap={1}>


        <TextField
          onDrop={handleDrop}
          label="ชื่อไฟล์"
          value={fileName}
          onChange={e => setFileName(e.target.value)}
          sx={{ flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <IconButton disableRipple={true} sx={{ p: 0 }} edge="end" onClick={e => setAnchorEl(e.currentTarget)}>
                  <ArrowDropDownCircleRoundedIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          variant="outlined"
          size="small"
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={(e) => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}>
          <List dense>
            {options.map((item, index) => (
              <ListItem key={index}><Button variant="outlined" fullWidth onClick={() => handleListItemClick(item)}>{item}</Button></ListItem>
            ))}
          </List>
        </Popover>
        {
          selectedFile ?
            <Box display="flex" gap={1}>
              <Button variant="contained" color="success" size="small" sx={{ minWidth: 20, width: 40 }} onClick={() => handleAddFile(selectedFile)}><DoneRoundedIcon /></Button>
              <Button variant="contained" color="error" size="small" sx={{ minWidth: 20, width: 40 }} onClick={() => setSelectedFile(null)}><CloseRoundedIcon /></Button>
            </Box>
            :
            <Button variant="contained" size="small" sx={{ width: 90 }} component="label">
              <input type="file" hidden onChange={handleSelectFile} />
              เลือกไฟล์
            </Button>
        }
      </Box>
      <Box display="flex" flexGrow={1} width="100%" 
        sx={{ borderRadius: 2, p: 2 }} 
        bgcolor="#f6f6f6" flexDirection="column"
        onDrop={handleDropMulti} onDragOver={handleDragOver}>
        {
          fileUploadData.serverFiles.length === 0 && fileUploadData.addedFiles.length === 0 ?

            <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%" >
              <SystemUpdateAltRounded sx={{ fontSize: 50, color: grey[500] }} />
              <Typography variant="h6" sx={{ color: grey[500] }}>Drop file here</Typography>
            </BoxFC>
            :
            [
              ...fileUploadData.serverFiles.map((item, index) => (
                <FileItem key={`s${index}`} index={index} file={item}
                  setFileUploadData={setFileUploadData}
                  fileMode={"serverFiles"} />
              )),
              ...fileUploadData.addedFiles.map((item, index) => (
                <FileItem key={`a${index}`} index={index} file={item}
                  setFileUploadData={setFileUploadData}
                  fileMode={"addedFiles"} />
              ))
            ]
        }
        {
          // fileUploadData.addedFiles.map((item, index) => (
          //   <FileItem key={`a${index}`} index={index} file={item}
          //     setFileUploadData={setFileUploadData}
          //     fileMode={"addedFiles"} />
          // ))
        }
      </Box>
    </Box>
  )
}

export default React.memo(FileUpload)

const FileItem = ({ index, file, fileMode, setFileUploadData }) => {

  const { label, isEdit } = file;
  const [tfValue, setTFValue] = useState("");

  const handleEditclick = () => {
    console.log("in handleEditClick file :: ", file)
    if (!isEdit) {
      setTFValue(label);
    }
    file.isEdit = !isEdit;
    file.label = isEdit ? tfValue : label
    setFileUploadData(oldState => ({ ...oldState }))
  }

  const handleDeleteClick = () => {
    if (isEdit) {
      file.isEdit = false;
      file.label = label;
      setFileUploadData(oldState => ({ ...oldState }));
    } else {
      setFileUploadData(oldState => ({
        ...oldState,
        [fileMode]: oldState[fileMode].filter(item => item !== file),
        deletedFiles: fileMode === "serverFiles" ? [...oldState.deletedFiles, file] : [...oldState.deletedFiles]
      }));
    }
  }
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1} mb={1}>
      {
        isEdit ?
          <TextField size="small"
            value={tfValue} onChange={e => setTFValue(e.target.value)}
            sx={{ bgcolor: "white", flexGrow: 1 }}
            inputProps={{ style: { fontSize: "0.8rem", height: 12 } }}
          />
          :
          <Button variant="outlined" size="small" disabled={false}
            sx={{ flexGrow: 1, 
              textTransform: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            onClick={() => window.open(file.imgLink)}><span style={{ overflow: "hidden", textOverflow: "ellipsis"}}>{label}</span></Button>
      }
      <Button variant="contained" size="small" sx={{ minWidth: 20, width: 30, height: 30 }} onClick={handleEditclick}>
        {isEdit ? <DoneRoundedIcon /> : <EditRoundedIcon />}
      </Button>
      <Button variant="contained" color="error" size="small" sx={{ minWidth: 20, width: 30, height: 30 }} onClick={handleDeleteClick}>
        {isEdit ? <CloseRoundedIcon /> : <DeleteRoundedIcon />}
      </Button>
    </Box>
  )
}