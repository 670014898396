
import GridCellExpand from "./GridCellExpand"
import { DataGridPro, GridToolbarContainer, GridToolbarExport, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useCallback, useContext, useState } from "react";
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popover } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from "dayjs";
import { lightBlue } from '@mui/material/colors';
import ShipmentDocDialog from "./ShipmentDocDialog";
import CheckobxFormControl from "components/CheckobxFormControl";
import { useEffect } from "react";
import { noYearColNms } from "./noYearColNms";
import { getPOIPlace } from "utils/getPOIPlace";
import { onKeyDownCopy } from "utils/dataGridOnCellKeyDownCopy";
import ShipmentDialog from "pages/Menu1/ShipmentManagement/ShipmentDialog";
import { FilePresentRounded } from "@mui/icons-material";
import CustomerDialog from "pages/Menu2/CustomerManagement/CustomerDialog";
import JobDialog from "pages/Menu2/JobManagement/JobDialog";
import DriverDialog from "pages/Menu2/DriverManagement/DriverDialog";
import TruckDialog from "pages/Menu2/TruckManagement/TruckDialog";
import POIDialog from "pages/Menu2/POIManagement/POIDialog";
import { UserContext } from "contexts/UserContext";

const modifyColumn = (innerColumns, setInnerColumns, setIsShowYear, isChecked) => {
  for (const col of innerColumns) {
    let width = 100;
    let format = "DD/MM HH:mm";
    if (isChecked) {
      width = 120;
      format = "DD/MM/YY HH:mm";
    }
    if (noYearColNms.includes(col.field)) {
      col.width = width;
      col.valueFormatter = (params) => params.value ? dayjs(params.value).format(format) : "-";

    }

    setInnerColumns([...innerColumns])
    setIsShowYear(isChecked);
  }
}

const ShowYear = ({ innerColumns, setInnerColumns, isShowYear, setIsShowYear }) => {

  // const apiRef = useGridApiContext();

  const handleCheckChange = useCallback((e) => {
    // const columnsx = apiRef.current.d
    // console.log("icolumnsx", columnsx)

    modifyColumn(innerColumns, setInnerColumns, setIsShowYear, e.target.checked);



    // apiRef.current.setState(o=>{
    //   console.log("ooooooooooo:", o)
    //   // return o
    //   return {
    //     ...o,
    //     columns: {
    //       all: columnsx.map(col=>col.field),
    //       columnVisibilityModel: columnsx.reduce((prev, cur)=>({...prev, [cur.field]: !cur.hide}), {}),
    //       lookup: columnsx.reduce((prev, cur)=>({
    //         ...prev,
    //         [cur.field]:{...cur}
    //       }), {})
    //     }
    //   }
    // })
  }, [innerColumns, setInnerColumns, setIsShowYear])
  return (
    <Button>
      <CheckobxFormControl sx={{ ml: 1 }} label="แสดงปี" checked={isShowYear} onChange={handleCheckChange} />
    </Button>
  )
}
const CustomToolbar = (props) => () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          utf8WithBom: true,
          // getRowsToExport: (params)=>{
          //   console.log("getRowToExport::", params.apiRef.current.getAllRowIds())
          //   return params.apiRef.current.getAllRowIds()
          // }
        }} />
      <ShowYear {...props} />
    </GridToolbarContainer>
  );
}
const ContextListItem = ({ label, icon, onClick }) => (
  <ListItem disablePadding>
    <ListItemButton onClick={onClick}>
      <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItemButton>
  </ListItem>
)

let mousePositionX = 0
let mousePositionY = 0
let value = null
let field = null
let row = null
let refId = null
const fieldNm = {
  JobNo: "ใบแจ้งลาก",
  Bkg: "ใบแจ้งลาก",
  CusSNm: "ลูกค้า",
  JobSNm: "งาน",
  DrvNNm: "พนักงานขับรถ",
  TukCd: "รถ",
  TakePOIId: "สถานที่",
  RtnPOIId: "สถานที่",
  LocPOIId: "สถานที่",
  POIId: "สถานที่",
  TakePlc: "สถานที่",
  RtnPlc: "สถานที่",
  Loc: "สถานที่",
}
const DataGridCellExpand = (props) => {

  const { user } = useContext(UserContext)

  const { columns, rows, extraContext } = props

  const [popOverOpen, setPopOverOpen] = useState(false)
  const [queryUrl, setQueryUrl] = useState(null)
  const [shpmId, setShpmId] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogShipmentOpen, setDialoShipmentOpen] = useState(false)
  const [dialogTukOpen, setDialogTukOpen] = useState(false)
  const [dialogDrvOpen, setDialogDrvOpen] = useState(false)
  const [dialogCusOpen, setDialogCusOpen] = useState(false)
  const [dialogJobOpen, setDialogJobOpen] = useState(false)
  const [dialogPOIOpen, setDialogPOIOpen] = useState(false)
  const [innerColumns, setInnerColumns] = useState(columns)
  const [isShowYear, setIsShowYear] = useState(false)

  const showSearch = Boolean(queryUrl)
  const showViewDetail = Boolean(refId)

  const apiRef = useGridApiRef();


  const handlePopoverOpen = (event) => {
    event.preventDefault();
    mousePositionX = event.pageX
    mousePositionY = event.pageY
    setPopOverOpen(true);
    console.log("props:: ", props)
    // console.log("event", event)
    // console.log("rows::", rows)
    console.log("event.currentTarget.dataset::", event.currentTarget.dataset)
    // console.log("event.currentTarget.parentElement.dataset::", event.currentTarget.parentElement.dataset)
    field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    row = rows.find((r) => r.id.toString() === id);

    if (["AptTm", "FinDte", "LevTm", "ArvTm", "TakeDteSt", "TakeDteEn", "RtnDteSt", "RtnDteEn"
      , "InvDte", "RcptDte", "BNDte", "RVDte", "RecvDocDte", "MemTm", "ShorDte"].includes(field) && value) {
      value = dayjs(value).format("YYYY-MM-DD HH:mm")
    } else if (field === "TakePlc") {
      value = getPOIPlace(row, "TAKE")
    } else if (field === "Loc") {
      value = getPOIPlace(row, "LOC")
    } else if (field === "RtnPlc") {
      value = getPOIPlace(row, "RTN")
    } else {
      value = row[field]
    }

    if (["ContNo", "JobNo", "Bkg", "JobOrdId"].includes(field) && value) {

      const pathName = window.location.pathname
      let path = `${field}/${value}`
      if (field === "JobNo" && row.ShpmId) {
        path = `ShpmId/${row.ShpmId}`
      } else if (field === "Bkg") {
        path = `BkgExact/${value}`
      }
      if (pathName.startsWith("/emily"))
        setQueryUrl(`/emily/DailyJob/${path}`)
      else if (pathName.startsWith("/client")) {
        setQueryUrl(`/client/DataQuery/${path}`)
      } else {
        setQueryUrl(null)
      }
    } else {
      setQueryUrl(null)
    }
    if (["JobNo"].includes(field) && value) {
      setShpmId(row.ShpmId ? row.ShpmId : null)
    } else {
      setShpmId(null)
    }
    if (["JobNo", "Bkg", "CusSNm", "JobSNm", "DrvNNm", "TukCd", "TakePOIId", "RtnPOIId"
      , "LocPOIId", "POIId", "TakePlc", "RtnPlc", "Loc"].includes(field)) {
      if (["JobNo", "Bkg"].includes(field)) {
        refId = row.ShpmId
      } else if (["TakePlc", "RtnPlc", "Loc"].includes(field)) {
        refId = row[field.replace("Plc", "") + "POIId"]
      } else if (field === "DrvNNm") {
        refId = row.DrvId
      } else if (field === "TukCd") {
        refId = row.TukId
      } else if (field === "CusSNm") {
        refId = row.CusId
      } else if (field === "JobSNm") {
        refId = row.JobId
      } else if (["POIId", "TakePOIId", "LocPOIId", "RtnPOIId"].includes(field)) {
        refId = row[field]
      } else {
        refId = row[field]
      }
    } else {
      refId = null
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value).then(setPopOverOpen(false))
  }, [])

  const handleSearch = useCallback(() => {
    var win = window.open(window.location.origin + queryUrl, '_blank');
    win.focus();
    setPopOverOpen(false)
  }, [queryUrl])

  const handleOpenShipmentDoc = useCallback(() => {
    setPopOverOpen(false)
    setDialogOpen(true)
  }, [])

  const handleOnCellKeyDown = useCallback((params, event) => {
    if (onKeyDownCopy(event, params.value)) return
  }, [])

  const handleOpenDetailDialog = useCallback(() => {
    if (["JobNo", "Bkg"].includes(field)) {
      setDialoShipmentOpen(true)
    } else if (["TakePlc", "RtnPlc", "Loc", "TakePOIId", "LocPOIId", "RtnPOIId"].includes(field)) {
      setDialogPOIOpen(true)
    } else if (field === "DrvNNm") {
      setDialogDrvOpen(true)
    } else if (field === "TukCd") {
      setDialogTukOpen(true)
    } else if (field === "CusSNm") {
      setDialogCusOpen(true)
    } else if (field === "JobSNm") {
      setDialogJobOpen(true)
    }
    setPopOverOpen(false)
  }, [])

  //onFinish do not thing for now (20240509)
  const onFinish = useCallback(() => { }, [])

  useEffect(() => {
    for (const col of columns) {
      if (!col.renderCell && !col.getActions) {
        col.renderCell = (params) => (
          <GridCellExpand
            value={(col.valueFormatter ? col.valueFormatter(params) : params.value) || ''}
            width={params.colDef.computedWidth}
            align={col.align} />
        )
      }
      col.headerClassName = "header-style"
    }
    setInnerColumns(columns.map(item => item))
  }, [columns])

  /**
   * Want to automatic add year b4 export csv but 
   * has a problem with need to wait data rerender 
   * after set state can setTimeout b4 fn() but i 
   * think that is not a good idea
   */
  // useEffect(()=>{
  //   console.log("apiReffffffffffffff:::", apiRef.current)
  //   const fn = apiRef.current.exportDataAsCsv
  //   apiRef.current.exportDataAsCsv = () =>{
  //     console.log("export click::")

  //     modifyColumn(innerColumns, setInnerColumns, setIsShowYear, true);
  //     fn({ utf8WithBom: true})
  //   }
  // }, [apiRef, innerColumns])
  return (
    <Box height="100%" sx={{
      '& .header-style': {
        bgcolor: (theme) => lightBlue[100]
      },
      '& .MuiDataGrid-columnSeparator	': {
        color: 'white!important'
      }
    }}>
      <DataGridPro
        apiRef={apiRef}
        componentsProps={{
          cell: {
            onContextMenu: handlePopoverOpen,
          },
          // row: {
          //   onKeyUp: handleOnKeyUp
          // }
        }}
        components={{
          NoRowsOverlay: () => (<Box mt={4} textAlign="center">ไม่พบข้อมูล</Box>),
          Toolbar: !props.hideToolbar && CustomToolbar({ innerColumns, setInnerColumns, isShowYear, setIsShowYear })
        }}
        onCellKeyDown={handleOnCellKeyDown}
        disableSelectionOnClick
        {...props}
        columns={innerColumns} />
      <Popover
        open={popOverOpen}
        onClose={() => setPopOverOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: mousePositionY, left: mousePositionX }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Paper>
          <List disablePadding>
            <ContextListItem label="คัดลอก" icon={<ContentCopyIcon />} onClick={handleCopy} />
            {showSearch &&
              <ContextListItem label="ค้นหา" icon={<SearchIcon />} onClick={handleSearch} />
            }
            {shpmId &&
              <ContextListItem label="เอกสารใบแจ้งลาก" icon={<FilePresentRounded />} onClick={handleOpenShipmentDoc} />
            }
            {
              showViewDetail && user &&  user.AccTypId === 1 &&
              <ContextListItem label={`ดูรายละเอียด ${fieldNm[field]}`} icon={<ArticleIcon />} onClick={handleOpenDetailDialog} />
            }
            {extraContext && extraContext.map(item => (
              <ContextListItem key={item.label} label={item.label} icon={item.icon}
                onClick={() => {
                  item.onClick(row, field, value)
                  setPopOverOpen(false)
                }} />
            ))
            }
          </List>
        </Paper>
      </Popover>
      <ShipmentDocDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} shpmId={shpmId} headerText={value} />
      {user && user.AccTypId === 1 &&
        <ShipmentDialog state={{ dialogOpen: dialogShipmentOpen, selectedId: refId, }}
          fn={{ setDialogOpen: setDialoShipmentOpen, onFinish: onFinish }} />
      }
      {user && user.AccTypId === 1 &&
        <CustomerDialog state={{ dialogOpen: dialogCusOpen, selectedCusId: refId }}
          fn={{ setDialogOpen: setDialogCusOpen, onFinish: onFinish }} />
      }
      {user && user.AccTypId === 1 &&
        <JobDialog state={{ dialogOpen: dialogJobOpen, selectedId: refId }}
          fn={{ setDialogOpen: setDialogJobOpen, onFinish: onFinish }} />
      }
      {user && user.AccTypId === 1 &&
        <DriverDialog state={{ dialogOpen: dialogDrvOpen, selectedId: refId }}
          fn={{ setDialogOpen: setDialogDrvOpen, onFinish: onFinish }} />
      }
      {user && user.AccTypId === 1 &&
        <TruckDialog state={{ dialogOpen: dialogTukOpen, selectedId: refId }}
          fn={{ setDialogOpen: setDialogTukOpen, onFinish: onFinish, }} />
      }
      {user && user.AccTypId === 1 &&
        <POIDialog selectedId={refId} dialogOpen={dialogPOIOpen} setDialogOpen={setDialogPOIOpen}
          onFinish={onFinish} lastFilter={null} />
      }
    </Box>
  )
}

export default DataGridCellExpand