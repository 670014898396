
export const truckApiNm = {
  getTruck: "/truck/getTruck",
  insertTruck: "/truck/insertTruck",
  updateTruck: "/truck/updateTruck",
  replaceTruckTag: "/truck/replaceTruckTag",
  updateTruckColumn: "/truck/updateTruckColumn",
  deleteTruck: "/truck/deleteTruck",
  updateFileName: "/truck/updateFileName",
  deleteFile: "/truck/deleteFile",
  uploadFile: "/truck/uploadFile"
}

export const truckColNm =  {
  TukId: "รหัสรถ",
  Code: "เลขรถ",
  LicNm: "ทะเบียนรถ",
  Model: "Model",
  Dscp: "รายละเอียด",
  IsActv: "Active",
  IsGas: "เชื้อเพลิง",
  Rmk: "หมายเหตุ",
  MileAdj: "การปรรับระยะเลขไมล์",
  TaxExpDte: "วันที่หมดภาษีรถ",
  InsExpDte: "วันที่หมดประกันรถ",
  GdInsExpDte: "วันที่หมดประกันสินค้า",
  AOLExpDte: "วันที่หมด พรบ. รถ",
  ModDte: "วันที่แก้ไข",
  ModAccId: "ผู้แก้ไข",
}

export const initDialogData = {
  TukId: null,
  Code: '',
  LicNm: '',
  Model: '',
  Dscp: '',
  IsActv: 1,
  IsGas: 0,
  Rmk: '',
  MileAdj: 0,
  TaxExpDte: null,
  InsExpDte: null,
  GdInsExpDte: null,
  AOLExpDte: null,
  ModDte: null,
  ModAccId: 0,
  ModAcc: null
}

export const initFilterData = {
  Code: null,
  LicNm: null,
}

export const act = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  ON_CHANGE_FILTER: 'ON_CHANGE_FILTER',
  ON_CHANGE_DIALOG_DATA: 'ON_CHANGE_DIALOG_DATA',
  CLEAR_FILTER: 'FILTER_CLEAR',
  SET_DATA_TABALE: 'SET_DATA_TABALE',
  SET_DIALOG_OPEN: 'SET_DIALOG_OPEN',
  SET_DIALOG_DATA: 'SET_DIALOG_DATA'
}