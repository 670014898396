export const saveFile = async ({ax, alertWarning, fileUploadData, setFileUploadData
  , apiUpdateFileName, apiDeleteFile, apiAddFile, key, refKey, refValue, extraColValues}) =>{
  for (const file of fileUploadData.serverFiles) {
    if (file.isEdit) {
      alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
      return;
    }
  }
  for (const file of fileUploadData.addedFiles) {
    if (file.isEdit) {
      alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
      return;
    }
  }
  // let hasFileChange = false
  for (const file of fileUploadData.serverFiles) {
    console.log("file.oLabel , file.label::", file.oLabel, file.label)
    if (file.oLabel !== file.label) {
      await ax.post(apiUpdateFileName, { [key]: file.id, Label: file.label })
      // hasFileChange = true
    }
  }
  for (const file of fileUploadData.deletedFiles) {
    await ax.post(apiDeleteFile, { [key]: file.id })
    fileUploadData.serverFiles = fileUploadData.serverFiles.filter(item=>item.id !== file.id)
    // dialogData.Files = dialogData.Files.filter(item => item.InvFileId !== file.id)
    // hasFileChange = true
  }
  for (const file of fileUploadData.addedFiles) {
    const data = new FormData();
    data.append("file", file.file);
    data.append(refKey, refValue);
    data.append("Label", file.label);
    for(const key in extraColValues){
      data.append(key, extraColValues[key])
    }
    const result = await ax.post(apiAddFile, data)
    file.fileNm = result.data
    fileUploadData.serverFiles.push(file)

    // dialogData.Files.push({
    //   InvId: selectedId,
    //   Label: file.fileNm,
    //   FileNm: result.data
    // })
    // hasFileChange = true
  }
  setFileUploadData(o=>({...o, deletedFiles: [], addedFiles: []}))
}