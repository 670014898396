
import Resizer from "react-image-file-resizer";

export const resizeImage = (file, option={maxWidth: 1000, maxHeight: 1000, quality: 85}) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, 
      option.maxWidth, 
      option.maxHeight, 
      "JPEG", 
      option.quality, 
      0,
      (uri) => {resolve(uri)},
      "file"
    );
  });