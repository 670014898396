import React, { useContext, useState, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import { Redirect, useRouteMatch, useLocation, Link, Switch, Route } from 'react-router-dom';
import { UserContext } from 'contexts/UserContext';
import { Container, AppBar } from '../appBarStyle'
import { pageName, navMenuItemsHeader } from './constant'
import { Box, Divider, IconButton, List, Typography, Toolbar, Drawer, SvgIcon, Avatar, useMediaQuery, Alert, AlertTitle, Snackbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import Loading from '../Loading';

import { ReactComponent as EmilyLogo } from "resource/emily_no_fill.svg";
import { loadMSData } from 'utils';
import { BoxFC } from 'components/BoxCustom';
import HeaderItem from './HeaderItem';

import packageJson from '../../../package.json';

import { extraRoute } from 'branch/pages/ExtraMainLayoutConstant';

import { ACC_TYP_EMP, defaultUserPref, TARGET_MODE_FINANCE, TARGET_MODE_OPERATION } from 'constants/accountConstant';
import TargetOperation from './TargetOperation';
import TargetFinance from './TargetFinance';
import notificationSound from '../../resource/sound/doorbell.wav'

import { getFirebaseToken, onMessageListener } from './firebase';

import PushPinIcon from '@mui/icons-material/PushPin';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { appBarHeight, drawerWidth } from '../appBarStyle'
import CheckupManagement from 'pages/menu7/Checkup/CheckupManagement';
import DriverAttend from 'pages/Menu1/DriverAttend/DriverAttend';
import RepairOrderManagement from 'pages/menu7/RepairOrderManagement/RepairOrderManagement';

// import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";
// import NotificationsIcon from '@mui/icons-material/Notifications';

//-----------------import page -------------------------------
// import HelloEmily from 'pages/HelloEmily';
const CustomerManagement = lazy(() => import('pages/Menu2/CustomerManagement'))
const JobManagement = lazy(() => import('pages/Menu2/JobManagement'))
const TruckManagement = lazy(() => import('pages/Menu2/TruckManagement'))
const DriverManagement = lazy(() => import('pages/Menu2/DriverManagement'))
const AddJobOrder = lazy(() => import('pages/Menu1/AddJobOrder/AddJobOrder'))
const AddJobOrderChild = lazy(() => import('pages/Menu1/AddJobOrder/AddJobOrderChild'))
const ShipmentManagement = lazy(() => import('pages/Menu1/ShipmentManagement'))
const DailyJob = lazy(() => import('pages/Menu1/DailyJob'))
const SendEmail = lazy(() => import('pages/Menu1/SendEmail'))
const ContactManagement = lazy(() => import('pages/ContactManagement'))
const ContainerDoc = lazy(() => import('pages/Menu1/ContainerDoc'))
const DailyExpense = lazy(() => import('pages/Menu1/DailyExpense'))
const ExpenseManagement = lazy(() => import('pages/Menu4/ExpenseManagement/ExpenseManagement'))
const ExpenseJob = lazy(() => import('pages/Menu4/ExpenseJob/ExpenseJob'))
const AddJobOrderExpense = lazy(() => import('pages/Menu3/AddJobOrderExpense/AddJobOrderExpense'))
const InvoiceManagement = lazy(() => import('pages/Menu3/InvoiceManagement/InvoiceManagement'))
const BillingNoteManagement = lazy(() => import('pages/Menu3/BillingNoteManagement/BillingNoteManagement'))
const ReceiptManagement = lazy(() => import('pages/Menu3/ReceiptManagement/ReceiptManagement'))
const ReceiveVoucherManagement = lazy(() => import('pages/Menu3/ReceiveVoucherManagement/ReceiveVoucherManagement'))
const ExpenseInspection = lazy(() => import('pages/Menu3/ExpenseInspection/ExpenseInspection'))
const AccountManagement = lazy(() => import('pages/Menu6/AccountManagement/AccountManagement'))
const AccountClientManagement = lazy(() => import('pages/Menu6/AccountClientManagement/AccountClientManagement'))
const ServerDataManagement = lazy(() => import('pages/Menu6/ServerDataManagement/ServerDataManagement'))
const ProfileButton = lazy(() => import('./ProfileButton'))
const UserSetting = lazy(() => import('pages/UserSetting/UserSetting'))
const BankAccountManagement = lazy(() => import('pages/Menu4/BankAccountManagement/BankAccountManagement'))
const ShoreManagement = lazy(() => import('pages/Menu1/ShoreManagement/ShoreManagement'))
const ContainerStatusSummary = lazy(() => import('pages/Menu1/ContainerStatusSummary/ContainerStatusSummary'))
const ReceiveVoucherReport = lazy(() => import('pages/Menu3/ReceiveVoucherReport/ReceiveVoucherReport'))
const MonthlyJobReport = lazy(() => import('pages/Menu5/Report/MonthlyJobReport'))
const DashboardOperation = lazy(() => import('branch/pages/Dashboard/DashboardOperation'))
const DriverIncome = lazy(() => import('pages/Menu3/DriverIncome/DriverIncome'))
const SubcontractReport = lazy(() => import('pages/Menu5/Report/SubcontractReport'))
const MonthlyIncomeReport = lazy(() => import('pages/Menu5/Report/MonthlyIncomeReport'))
const KnowYourCustomer = lazy(() => import('pages/Menu5/Report/KnowYourCustomer/KnowYourCustomer'))
const OverDueReport = lazy(() => import('pages/Menu5/Report/OverDueReport'))
const EmilyExpresss = lazy(() => import('pages/Menu4/EmilyExpress/EmilyExpress'))
const SubcontractJob = lazy(() => import('pages/Menu4/SubcontractJob/SubcontractJob'))
const SubcontractInvoice = lazy(() => import('pages/Menu3/SubcontractInvoice/SubcontractInvoice'))
const SubcontractManagement = lazy(() => import('pages/Menu2/SubcontractManagement/SubcontractManagement'))
const ContainerClosingSummary = lazy(() => import('pages/Menu1/ContainerClosingSummary/ContainerClosingSummary'))
const PunctualReport = lazy(() => import('pages/Menu5/PunctualReport/PunctualReport'))
const DriverJobReport = lazy(() => import('pages/Menu5/DriverJobReport/DriverJobReport'))
const TagManagement = lazy(() => import('pages/Menu2/TagManagement/TagManagement'))
const DriverPerformance = lazy(() => import('pages/Menu5/Report/DriverPerformance/DriverPerformance'))
const CreditNoteManagement = lazy(() => import('pages/Menu3/CreditNoteManagement/CreditNoteManagement'))
const LogManagement = lazy(() => import('pages/Menu1/LogManagement/LogManagement'))
const JobOrderImageManagement = lazy(() => import('pages/Menu1/JobOrderImage/JobOrderImageManagement'))
const DriverStatusContainer = lazy(() => import('pages/Menu1/DriverStatus/DriverStatusContainer'))
const BookingManagement = lazy(() => import('pages/Menu1/BookingManagement/BookingManagement'))
const FuelManagement = lazy(() => import('pages/Menu1/FuelManagement/FuelManagement'))
const POIManagement = lazy(() => import('pages/Menu2/POIManagement/POIManagement'))
const Map = lazy(() => import('pages/Menu1/Map/Map'))
const FuelReport = lazy(() => import('pages/Menu5/FuelReport/FuelReport'))
//---------------end import page-----------------------------


const initSnackbarData = {
  open: false,
  NName: "",
  TakePlc: "",
  Loc: ""
}
let isFocus = false;
let isVisible = true;

// const drawerWidth = 260;
const sxPermanetDrawer = {
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}

const sxTemporaryDrawer = {
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
}

const sxPermanetAppbar = { width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }

function MainLayout() {
  const { user, setUser } = useContext(UserContext);
  const { isLoading, ax, msData, setMSData, setBadge } = useContext(GlobalStateContext);
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const [isCheckPms, setIsCheckPms] = useState(false)
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [isPined, setIsPinded] = useState(false)
  const [childrenOpen, setChildrenOpen] = useState(navMenuItemsHeader.reduce((obj, item) => {
    return { ...obj, [item.num]: false }
  }, {}))
  const [snackBarData, setSnackbarData] = useState(initSnackbarData)

  // const [isTokenFound, setTokenFound] = useState(false);


  const w500 = useMediaQuery('(max-width:500px)');

  const handleDrawerOpen = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  const toggleExpand = useCallback((num) => {
    setChildrenOpen(o => {
      o[num] = !o[num]
      return { ...o }
    })
  }, [])

  const generateNavMenuItem = useCallback((url, setOpen) => {
    return (
      <List sx={{ pt: 0 }}>
        {
          navMenuItemsHeader.map((item) => (
            <BoxFC key={item.num}
              sx={{
                gap: 1,
                mt: 1,
                display: user.Pms.find(pm => item.permission.split(";").includes(pm.Code) && pm.Mode > 0) ? "flex" : "none"
              }} >
              <HeaderItem
                userPms={user.Pms}
                childrenOpen={childrenOpen[item.num]}
                toggleExpand={toggleExpand}
                url={url} setOpen={setOpen}
                headerItem={item} />
              <Divider />
            </BoxFC >
          ))
        }
      </List>
    )
  }, [user, childrenOpen, toggleExpand])

  const handlePinClick = useCallback(() => {
    setIsPinded(o => !o)
  }, [])
  const handleSnackbarClose = useCallback(() => {
    setSnackbarData(initSnackbarData)
  }, [])

  const onFocus = useCallback(() => {
    isFocus = true;
    console.log("Tab isFocus::", isFocus);
  }, []);

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = useCallback(() => {
    isFocus = false;
    console.log("Tab isFocus::", isFocus);
  }, []);

  const onVisibilitychange = useCallback((e) => {
    isVisible = document.visibilityState === "visible"
    console.log("tab is visible::", isVisible)
  }, [])

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    window.addEventListener("visibilitychange", onVisibilitychange);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("visibilitychange", onVisibilitychange);
    };
  }, [onBlur, onFocus, onVisibilitychange]);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
      return
    } else {
      console.log("Browser support desktop notification")
      // Notification.requestPermission();

    }
    console.log("localStorage.getItem(fcmToken)", localStorage.getItem("fcmToken"))
    if (localStorage.getItem("fcmToken") === "null") {
      getFirebaseToken(ax);
    }
    onMessageListener((payload) => {

      if (isFocus) {

        console.log("FCM onMessage::", payload);

        const data = payload.data
        if (data.type === "CONT_NO" && data.data) {
          try {
            if (isVisible) {
              const audio = new Audio(notificationSound);
              audio.play();
            }
          } catch (ex) {
            console.log(ex)
          }
          const dataObj = JSON.parse(data.data)
          setSnackbarData({
            open: true,
            title: `${dataObj.NName} ส่งข้อมูลตู้`,
            body: `${msData.poiObj(dataObj.TakePOIId)} - ${msData.poiObj(dataObj.LocPOIId)}`,
          })
        } else if (data.type === "TESTx") {
          const audio = new Audio(notificationSound);
          audio.play();
          const dataObj = data.data ? JSON.parse(data.data) : { TestId: 10, TestNm: "Default TestNm" }
          setSnackbarData({
            open: true,
            title: `TestId:: ${dataObj.TestId} `,
            body: `RawData:: ${data.data} `,
          })
        }

        const options = {
          body: payload.notification.body,
          // icon: 'https://www.vkf-renzel.com/out/pictures/generated/product/1/356_356_75/r12044336-01/general-warning-sign-10836-1.jpg?    auto=compress&cs=tinysrgb&dpr=1&w=500',
          dir: 'ltr',
        };

        new Notification(payload.notification.title, options);
        // new Notification('Hello World')
      } else {
        console.log("tab not focus dont show noti")
      }
    })

  }, [ax, msData])


  useEffect(() => {
    //check that user has permission to access this page
    setIsCheckPms(false)

    const localMsData = JSON.parse(localStorage.getItem("msData"))
    const userData = JSON.parse(localStorage.getItem("userData"))
    const badgeData = JSON.parse(localStorage.getItem("badgeData"))
    console.log("localMsData::", localMsData)
    if (localMsData && userData && badgeData) {
      setMSData(localMsData)
      setUser(userData)
      setBadge(badgeData)
    } else {

      loadMSData(ax, setMSData, setUser, setBadge)
    }
  }, [setMSData, setUser, ax, setBadge])

  useEffect(() => {
    if (!user) return

    const page = pathname.replace(url, '')
    const pages = page.split('/');
    if (!pages[1] || pages[1] === '') {
      setTitle('Emily')
      setIsCheckPms(true)
    } else {
      let foundItem = null
      let foundHeader = null

      for (const header of navMenuItemsHeader) {
        const found = header.children.find(item => item.page === pages[1])
        if (found) {
          foundItem = found
          foundHeader = header
          break
        }
      }
      const pagePmStr = foundItem.permission || foundHeader.permission || ""
      const pagePms = pagePmStr.split(";")
      const userPms = user.Pms.filter(item => item.Mode > 0).map(item => item.Code)
      if (!pagePms.some(pg => userPms.includes(pg))) {
        console.log("redirect now!!!!")
        window.location = "/"
        return
      }
      setIsCheckPms(true)
      const title = `${foundItem.num} ${pageName[pages[1]]}`
      setTitle(title)
      document.title = `Emily-${title}`
    }
  }, [pathname, url, user])

  const calculateTarget = useMemo(() => {
    if (w500) return <Box flex={1} />

    const userPref = (user && user.UsrPref && JSON.parse(user.UsrPref)) || defaultUserPref
    const targetData = userPref.TargetData || defaultUserPref.TargetData
    console.log("targetData::", targetData);
    if (targetData.isShowTarget) {
      if (targetData.targetMode === TARGET_MODE_OPERATION) {
        return <TargetOperation />
      } else if (targetData.targetMode === TARGET_MODE_FINANCE) {
        return <TargetFinance />
      } else return null
    } else {
      return null
    }
  }, [user, w500])

  useEffect(() => {
    const userPref = (user && user.UsrPref && JSON.parse(user.UsrPref)) || defaultUserPref
    setChildrenOpen(navMenuItemsHeader.reduce((obj, item) => {
      return { ...obj, [item.num]: userPref.DefaultOpenMenu[item.num] || false }
    }, {}))


  }, [user])

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      /**
       * dont know why event fire with key "__some_random_key_you_are_not_going_to_use__"
       * may be other package fire this event
       */
      console.log("storage event::", event);
      try {
        const newValue = JSON.parse(event.newValue)
        if (event.key === "msData") {
          setMSData(newValue)
        } else if (event.key === "userData") {
          setUser(newValue)
        } else if (event.key === "badgeData") {
          setBadge(newValue)
        }
      } catch (ex) {
        /**
         * cannto parse json do nothing
         */
        //console.log(ex)
      }
    })
  }, [setMSData, setUser, setBadge])
  console.log(`Render MainLayout user context::`, user);
  return (
    msData && isCheckPms ?
      user.AccTypId === ACC_TYP_EMP ?
        <Box display="flex" >
          <AppBar position="fixed" sx={isPined ? sxPermanetAppbar : null}>
            <Toolbar variant="dense">
              {!isPined &&
                <IconButton size="large" edge="start" color="inherit"
                  aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
              }
              <Typography noWrap variant="h6" component="div" sx={{ textOverflow: "ellipsis" }}>
                {title}
              </Typography>
              {calculateTarget}
              {/* <Badge badgeContent={4} color="error" sx={{ mr: 2 }}>
                <IconButton sx={{ p: 0 }}>
                  <NotificationsIcon sx={{ color: "white" }} />
                </IconButton>
              </Badge> */}
              <ProfileButton />
            </Toolbar>
          </AppBar>
          <Drawer anchor="left" open={open}
            onClose={() => setOpen(false)}
            style={{ overflow: "none" }}
            variant={isPined ? "permanent" : "temporary"}
            sx={isPined ? sxPermanetDrawer : sxTemporaryDrawer}>
            <Box position="relative">
              <Link to={`${url}`}>
                <Box p={1} display="flex" gap={1} alignItems="center" justifyContent="center" sx={{ bgcolor: 'primary.main', height: appBarHeight }} >
                  <Avatar alt="emily" sx={{ bgcolor: "white", width: 55, height: 45 }}>
                    <SvgIcon sx={{ fontSize: 40, color: "primary.dark" }}>
                      <EmilyLogo />
                    </SvgIcon>
                  </Avatar>
                  {open && <Typography variant='h5' textAlign='center' sx={{ color: "white", fontWeight: "bold" }}>Emily</Typography>}

                </Box>
              </Link>
            </Box>
            <Box position={'absolute'} right={0} top={0}>
              <IconButton onClick={handlePinClick}>
                {isPined ? <ArrowBackIcon sx={{ color: "white" }} /> : <PushPinIcon sx={{ color: "white" }} />}
              </IconButton>
            </Box>
            <Divider />
            <Box style={{ overflowY: "auto" }}>
              {generateNavMenuItem(url, setOpen)}
              <Typography textAlign="center" mt={1} variant="body2">V.{packageJson.version}</Typography>
            </Box>

          </Drawer>

          <Container>
            {/**
             * useSuspense to lazy load page
             * coz got this warning when build with npm run build
             * 
             * The bundle size is significantly larger than recommended.
             * Consider reducing it with code splitting: https://goo.gl/9VhYWB
             * You can also analyze the project dependencies: https://goo.gl/LeUzfb
             * 
             * Suspense can use with setLoading while calling api 
             * but now now sure to using it
             * 
             * So fallback set to null then use normal setLoading
             * 
             * After build with npm run build
             * There is also warning 
             * Next thing to try is reduce bundle size by materila ui
             * After run with analyze it show that material icons using large size
             * Materail document said better using barbel import but leave it for now
             */}
            <Suspense fallback={null}>
              <Box component="main" height="100%" sx={{ p: 1, mt: 1, width: "100%" }}>
                {/* <Button onClick={()=>setSnackbarOpen(o=>!o)}>toggle</Button> */}
                <Snackbar open={snackBarData.open}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={10000} onClose={handleSnackbarClose}>
                  <Alert variant="filled" onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    <AlertTitle>{snackBarData.title}</AlertTitle>
                    {snackBarData.body}
                  </Alert>
                </Snackbar>
                <Switch>
                  <Route exact path={`${path}`}><DashboardOperation /></Route>
                  <Route path={`${path}/CustomerManagement`}><CustomerManagement /></Route>
                  <Route path={`${path}/JobManagement`} ><JobManagement /> </Route>
                  <Route path={`${path}/TruckManagement`} ><TruckManagement /> </Route>
                  <Route path={`${path}/DriverManagement`} ><DriverManagement /> </Route>
                  <Route path={`${path}/ShipmentManagement`} ><ShipmentManagement /> </Route>
                  <Route path={`${path}/AddJobOrder`} ><AddJobOrder /> </Route>
                  <Route path={`${path}/AddJobOrderChild/:jobOrdId`} ><AddJobOrderChild /> </Route>
                  <Route path={`${path}/DailyJob/:mField/:mValue`} ><DailyJob /> </Route>
                  <Route path={`${path}/DailyJob/:mJobOrdId`} ><DailyJob /> </Route>
                  <Route path={`${path}/DailyJob`} ><DailyJob /> </Route>
                  <Route path={`${path}/SendEmail/:jobOrdIdsStr`} ><SendEmail /> </Route>
                  <Route path={`${path}/SendEmail`} ><SendEmail /> </Route>
                  <Route path={`${path}/ContactManagement`} ><ContactManagement /> </Route>
                  <Route path={`${path}/ContainerDoc`} ><ContainerDoc /> </Route>
                  <Route path={`${path}/DailyExpense`} ><DailyExpense /> </Route>
                  <Route path={`${path}/ExpenseManagement`} ><ExpenseManagement /> </Route>
                  <Route path={`${path}/ExpenseJob`} ><ExpenseJob /> </Route>
                  <Route path={`${path}/AddJobOrderExpense`} ><AddJobOrderExpense /> </Route>
                  <Route path={`${path}/InvoiceManagement`} ><InvoiceManagement /> </Route>
                  <Route path={`${path}/BillingNoteManagement`} ><BillingNoteManagement /> </Route>
                  <Route path={`${path}/ReceiptManagement`} ><ReceiptManagement /> </Route>
                  <Route path={`${path}/ReceiveVoucherManagement`} ><ReceiveVoucherManagement /> </Route>
                  <Route path={`${path}/ExpenseInspection/:keyValue`} ><ExpenseInspection /> </Route>
                  <Route path={`${path}/ExpenseInspection`} ><ExpenseInspection /> </Route>
                  <Route path={`${path}/AccountManagement`} ><AccountManagement /> </Route>
                  <Route path={`${path}/AccountClientManagement`} ><AccountClientManagement /> </Route>
                  <Route path={`${path}/ServerDataManagement`} ><ServerDataManagement /> </Route>
                  <Route path={`${path}/UserSetting`} ><UserSetting /> </Route>
                  <Route path={`${path}/BankAccountManagement`} ><BankAccountManagement /> </Route>
                  <Route path={`${path}/ShoreManagement`} ><ShoreManagement /> </Route>
                  <Route path={`${path}/ContainerStatusSummary`} ><ContainerStatusSummary /> </Route>
                  <Route path={`${path}/ReceiveVoucherReport`} ><ReceiveVoucherReport /> </Route>
                  <Route path={`${path}/DriverIncome`} ><DriverIncome /> </Route>
                  <Route path={`${path}/MonthlyJobReport`} ><MonthlyJobReport /> </Route>
                  <Route path={`${path}/SubcontractReport`} ><SubcontractReport /> </Route>
                  <Route path={`${path}/MonthlyIncomeReport`} ><MonthlyIncomeReport /> </Route>
                  <Route path={`${path}/KnowYourCustomer`} ><KnowYourCustomer /> </Route>
                  <Route path={`${path}/OverDueReport`} ><OverDueReport /> </Route>
                  <Route path={`${path}/EmilyExpress`} ><EmilyExpresss /> </Route>
                  <Route path={`${path}/SubcontractJob`} ><SubcontractJob /> </Route>
                  <Route path={`${path}/SubcontractInvoice`} ><SubcontractInvoice /> </Route>
                  <Route path={`${path}/SubcontractManagement`} ><SubcontractManagement /> </Route>
                  <Route path={`${path}/ContainerClosingSummary`} ><ContainerClosingSummary /> </Route>
                  <Route path={`${path}/DriverStatus`} ><DriverStatusContainer /> </Route>
                  <Route path={`${path}/PunctualReport`} ><PunctualReport /> </Route>
                  <Route path={`${path}/DriverJobReport`} ><DriverJobReport /> </Route>
                  <Route path={`${path}/TagManagement`} ><TagManagement /> </Route>
                  <Route path={`${path}/DriverPerformance`} ><DriverPerformance /> </Route>
                  <Route path={`${path}/CreditNoteManagement/:invId`} ><DriverPerformance /> </Route>
                  <Route path={`${path}/CreditNoteManagement`} ><CreditNoteManagement /> </Route>
                  <Route path={`${path}/LogManagement`} ><LogManagement /> </Route>
                  <Route path={`${path}/JobOrderImageManagement`} ><JobOrderImageManagement /> </Route>
                  <Route path={`${path}/BookingManagement`} ><BookingManagement /> </Route>
                  <Route path={`${path}/FuelManagement`} ><FuelManagement /> </Route>
                  <Route path={`${path}/POIManagement`} ><POIManagement /> </Route>
                  <Route path={`${path}/Map`} ><Map /> </Route>
                  <Route path={`${path}/FuelReport`} ><FuelReport /> </Route>
                  <Route path={`${path}/CheckupManagement`} ><CheckupManagement /> </Route>
                  <Route path={`${path}/RepairOrderManagement`} ><RepairOrderManagement /> </Route>
                  <Route path={`${path}/DriverAttend`} ><DriverAttend /> </Route>
                  {extraRoute &&
                    extraRoute(path).map(item => item)
                  }
                  <Route path={`*`}><Redirect to="/404" /></Route>
                </Switch>
              </Box>
            </Suspense>
          </Container>
          {
            isLoading && <Loading />
          }
        </Box>
        :
        <Redirect to="/client/Today" />
      :
      <div>Loading</div>
  )
};

export default MainLayout;