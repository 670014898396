import { BoxFR } from "components/BoxCustom";
import React, { useContext, useEffect, useState } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from "dayjs";
import { CircularProgress, Typography } from "@mui/material";
import { purple } from '@mui/material/colors';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import { Box } from "@mui/system";
import DataItem from "./DataItem";
import DataItem2 from "./DataItem2";
import { coreApiNm } from "constants/serverConstant";
import { TodayRounded } from "@mui/icons-material";




const ContNoBox = () => (
  <Box fontSize={10} fontWeight="bold" sx={{ color: purple[500], bgcolor: "white", px: 0.5, borderRadius: 0.8 }}>
    <Box >AB</Box>
    <Box mt={-1}>CU</Box>
  </Box>
)
const TmrwBox = () => (
  <>

    <CalendarTodayIcon sx={{ fontSize: 28 }} />
    <Typography position="absolute" top={16} left={14} variant="subtitle2" fontSize={12} fontWeight="bold">+1</Typography>
  </>
)
const TargetOperation = () => {
  const { ax, targetTimestamp } = useContext(GlobalStateContext)

  const [targetData, setTargetData] = useState({
    isShowLoading: false,
    numTodayJob: 0,
    numTmrwJob: 0,
    numDeliver: 0,
    numHaveContNo: 0,
    numContNoTotal: 0,
    numThisMonth: 0,
    numLastMonth: 0
  })


  useEffect(() => {
    const getData = () => {
      setTargetData(o => ({ ...o, isShowLoading: true }))
      ax.post(coreApiNm.getTargetOperation, {}, false).then(value => {
        if (value.data) {
          setTargetData({ ...value.data, isShowLoading: false })
          value.data.refreshTime = dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
          localStorage.setItem("targetOperation", JSON.stringify(value.data))
        } else {
          setTargetData(o => ({ ...o, isShowLoading: false }))
        }
      })
    }
    const checkAndGetData = () => {
      const targetOperatoinStr = localStorage.getItem("targetOperation")
      let shouldGetData = false
      if (!targetOperatoinStr) {
        shouldGetData = true
      } else {
        const targetOperation = JSON.parse(targetOperatoinStr)
        console.log("after 5 min::", dayjs(targetOperation.refreshTime).add(5, "minute").isBefore(dayjs()))
        if (dayjs(targetOperation.refreshTime).add(5, "minute").isBefore(dayjs())) {
          shouldGetData = true
        } else {
          setTargetData(targetOperation)
        }
      }
      console.log("shouldGetData::", shouldGetData)
      if (shouldGetData) {
        getData()
      }
    }

    getData()
    const id = setInterval(checkAndGetData, 1000 * 60 * 5)
    console.log("targetTimeStamp::", targetTimestamp)
    return () => clearInterval(id);
  }, [ax, targetTimestamp])
  return (
    <BoxFR justifyContent="center" sx={{ gap: 2, overflow: "hidden", flex: "1 100 0" }}>
      <DataItem2 title="งานทั้งหมดวันนี้"
        link={`/emily/DailyJob/AptTm/${dayjs().format("YYYY-MM-DD_00:00:00.000")}_${dayjs().format("YYYY-MM-DD_23:59:59.997")}`}
        header={<TodayRounded />}
        data={`${targetData.numTodayJob}`} />
      <Box sx={{ overflow: "visible" }}>
        <DataItem title="เป้าหมายงานเดือนนี้ / เดือนที่แล้ว"
          value={targetData.numThisMonth === 0 ? 0
            : targetData.numThisMonth > targetData.numLastMonth ? 100
              : targetData.numThisMonth / targetData.numLastMonth * 100}
          header={<GpsFixedIcon />}
          data={`${targetData.numThisMonth}/${targetData.numLastMonth}`} />
      </Box>
      <Box sx={{ overflow: "visible" }}>
        <DataItem title="ปล่อยงานแล้ว (ไปโรงงาน)"
          value={targetData.numTodayJob === 0 ? 0 : targetData.numDeliver / targetData.numTodayJob * 100}
          header={<LocalShippingRoundedIcon />}
          data={`${targetData.numDeliver}/${targetData.numTodayJob}`} />
      </Box>

      <Box sx={{ overflow: "visible" }}>
        <DataItem title="งานส่งออกได้เบอร์ตู้แล้ว"
          value={targetData.numContNoTotal === 0 ? 0 : targetData.numHaveContNo / targetData.numContNoTotal * 100}
          header={<ContNoBox />}
          data={`${targetData.numHaveContNo}/${targetData.numContNoTotal}`} />
      </Box>
      <Box sx={{ overflow: "visible" }}>

        <DataItem2 title="งานพรุ่งนี้"
          link={`/emily/DailyJob/AptTm/${dayjs().add(1, "day").format("YYYY-MM-DD_00:00:00.000")}_${dayjs().add(1, "day").format("YYYY-MM-DD_23:59:59.997")}`}
          header={<TmrwBox />}
          data={`${targetData.numTmrwJob}`} />
      </Box>
      <Box width={30} display="flex" >
        {targetData.isShowLoading && <CircularProgress size={30} color="inherit" />}
      </Box>
    </BoxFR>
  )
}

export default TargetOperation