import { alertError } from "components/Alert"

const isFileOverSize =(file) => {
  if (file.size > (9.8 * 1024 * 1024)) {
    alertError("ไฟล์ ใหญ่เกิน 5MB ไม่สามารถเพิ่มได้")
    return true
  } else {
    return false
  }
}

export default isFileOverSize