import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import { Box, Dialog, DialogContent, TextField, FormLabel, RadioGroup, Radio, Checkbox, FormControlLabel, InputAdornment, Typography, Paper } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData, truckColNm, truckApiNm } from 'constants/truckConstant';
import useDialogOpen from "hooks/useDialogOpen";
import { loadMSData, validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { BoxFC, BoxFR } from "components/BoxCustom";
import FileUpload from "components/FileUplaod/FileUpload";
import { initFileUploadData } from "constants/fileUploadConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { FILDER_TRUCK_FILE } from "constants/serverConstant";
import { alertConfirmDelete, alertWarning } from "components/Alert";
import { saveFile } from "components/FileUplaod/saveFile";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { blueGrey } from "@mui/material/colors";
import TagBox from "components/TagBox";

let originalDialogData = { ...initDialogData }
let originalTagIds = []

const TruckDialog = ({ state, fn }) => {
  const { dialogOpen, selectedId, lastFilter } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const [licFileUploadData, setLicFileUploadData] = useState(initFileUploadData())
  const [taxFileUploadData, setTaxFileUploadData] = useState(initFileUploadData())
  const [insFileUploadData, setInsFileUploadData] = useState(initFileUploadData())
  const [aolFileUploadData, setAOLFileUploadData] = useState(initFileUploadData())
  const [tagData, setTagData] = useState([])

  const { ax, msData, setMSData } = useContext(GlobalStateContext)

  const datePickerProp = useCallback((name) => ({
    label: truckColNm[name],
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData[name],
    onChange: (newValue) => setDialogData((o) => ({ ...o, [name]: newValue })),
    renderInput: (params) => <TextField fullWidth size="small" {...params} />
  }), [dialogData])

  const modifyData = useCallback((dialogData) => {
    const makeFiles = (files) => {
      return files.map(item => ({
        id: item.TukFileId,
        fileNm: item.FileNm,
        label: item.Label,
        oLabel: item.Label,
        TukId: selectedId,
        isEdit: false,
        file: null,
        imgLink: `${window.location.origin}/${FILDER_TRUCK_FILE}${item.TukId}/${item.FileNm}`,
      }))
    }
    const licFile = makeFiles(dialogData.Files.filter(item => !item.FileTyp || item.FileTyp === "LIC"))
    const taxFile = makeFiles(dialogData.Files.filter(item => item.FileTyp === "TAX"))
    const InsFile = makeFiles(dialogData.Files.filter(item => item.FileTyp === "INS"))
    const AOLFile = makeFiles(dialogData.Files.filter(item => item.FileTyp === "AOL"))
    delete originalDialogData.Files
    
    if(dialogData.Tags){
      originalTagIds = dialogData.Tags.map(tag=> tag.TagId) 
    }
    originalDialogData = { ...dialogData}

    setLicFileUploadData({ ...initFileUploadData(), serverFiles: licFile })
    setTaxFileUploadData({ ...initFileUploadData(), serverFiles: taxFile })
    setInsFileUploadData({ ...initFileUploadData(), serverFiles: InsFile })
    setAOLFileUploadData({ ...initFileUploadData(), serverFiles: AOLFile })

    if(dialogData.Tags){
      setTagData(dialogData.Tags.map(tag=>({id: tag.TagId, label: tag.Name, refId: tag.TblAbbr})))
    }

    return dialogData
  }, [selectedId])

  useDialogOpen(dialogOpen, selectedId, truckApiNm.getTruck, "TukId", setDialogData, initDialogData, modifyData)

  const validation = useMemo(() => ({
    Code: dialogData.Code === '',
    LicNm: dialogData.LicNm === '',
    MileAdj: dialogData.MileAdj === '',
  }), [dialogData]);

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: truckColNm[name],
    value: dialogData[name],
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const insertUpdateTruck = useCallback(async (dialogData) => {
    let isSame = true
    for (const key in originalDialogData) {
      if (originalDialogData[key] !== dialogData[key]) {
        isSame = false
        break;
      }
    }

    const newTagIds = tagData.map(tag=>tag.id)
    
    const isTagSame =  originalTagIds.every(num => newTagIds.includes(num)) 
      && newTagIds.every(num => originalTagIds.includes(num));

    let hasChange = false
    let tukId = dialogData.TukId
    if (!isSame) {
      hasChange = true
      if(!isTagSame){
        dialogData.TagIds = newTagIds
      }
      let value;
      if (dialogData.TukId > 0) {
        tukId = dialogData.TukId
        value = await ax.post(truckApiNm.updateTruck, { ...dialogData });
      } else {
        value = await ax.post(truckApiNm.insertTruck, { ...dialogData });
      }


      if (value.data) {
        tukId = value.data
        loadMSData(ax, setMSData, null)
      }
    } else if(!isTagSame){
      hasChange = true
      await ax.post(truckApiNm.replaceTruckTag, {TukId: dialogData.TukId, TagIds: newTagIds})
    }
    if (licFileUploadData.addedFiles.length > 0 || licFileUploadData.deletedFiles.length > 0
      || taxFileUploadData.addedFiles.length > 0 || taxFileUploadData.deletedFiles.length > 0
      || insFileUploadData.addedFiles.length > 0 || insFileUploadData.deletedFiles.length > 0
      || aolFileUploadData.addedFiles.length > 0 || aolFileUploadData.deletedFiles.length > 0) {
      hasChange = true
    }
    if (tukId) {
      const localSaveFile = (fileUploadData, setFileUploadData, fileTyp) => {
        return saveFile({
          ax,
          alertWarning,
          fileUploadData,
          setFileUploadData,
          apiUpdateFileName: truckApiNm.updateFileName,
          apiDeleteFile: truckApiNm.deleteFile,
          apiAddFile: truckApiNm.uploadFile,
          key: "TukFileId",
          refKey: "TukId",
          refValue: tukId,
          extraColValues: { FileTyp: fileTyp },
        })
      }

      await localSaveFile(licFileUploadData, setLicFileUploadData, "LIC")
      await localSaveFile(taxFileUploadData, setTaxFileUploadData, "TAX")
      await localSaveFile(insFileUploadData, setInsFileUploadData, "INS")
      await localSaveFile(aolFileUploadData, setAOLFileUploadData, "AOL")
    }
    if (hasChange) {
      onFinish()
    }
    setDialogOpen(false);
  }, [ax, setMSData, licFileUploadData, taxFileUploadData, insFileUploadData
    , aolFileUploadData, onFinish, setDialogOpen, tagData])

  const deleteTruck = useCallback((tukId) => {
    alertConfirmDelete(() => {
      ax.post(truckApiNm.deleteTruck, { TukId: tukId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false);
          loadMSData(ax, setMSData, null)
        }
      })
    })
  }, [ax, setMSData, lastFilter, onFinish, setDialogOpen])

  useEffect(() => {
    if (!dialogOpen) {
      originalDialogData = { ...initDialogData }
      setLicFileUploadData(initFileUploadData())
      setTaxFileUploadData(initFileUploadData())
      setInsFileUploadData(initFileUploadData())
      setAOLFileUploadData(initFileUploadData())
      setTagData([])
    }
  }, [dialogOpen]) 

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขรถ" : "เพิ่มรถ"}
        onDeleteClick={selectedId > 0 ? () => deleteTruck(selectedId) : null}
      />
      <DialogContent sx={{ bgcolor: blueGrey[50] }}>
        <BoxFR sx={{ alignItems: "stretch" }} mt={1}  >
          <Paper sx={{ px: 2, py: 1 }}>
            <Box display='flex' flexDirection='column' gap={1} width={250}>
              <Typography variant="h6" mb={-1} >ข้อมูลรถ</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dialogData.IsActv === 1 ? true : false}
                    onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsActv: e.target.checked ? 1 : 0 })) }}
                  />
                }
                label={truckColNm.IsActv} />
              <TextField {...dialogDataProp('Code')} required />
              <TextField {...dialogDataProp('LicNm')} required />
              <TextField {...dialogDataProp('Model')} />
              <TextField {...dialogDataProp('Dscp')} multiline rows={2} />
              <FormLabel component="legend" sx={{ mb: -2 }}>{truckColNm.IsGas}</FormLabel>
              <RadioGroup row name='IsGas' value={dialogData.IsGas}
                onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsGas: e.target.value })) }}
              >
                <FormControlLabel value={0} control={<Radio />} label="น้ำมัน" />
                <FormControlLabel value={1} control={<Radio />} label="แก๊ส" />
                <FormControlLabel value={2} control={<Radio />} label="ไฟฟ้า" />
              </RadioGroup>
              <TextField {...dialogDataProp('MileAdj')} required type="number"
                inputProps={{ style: { textAlign: "right" } }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />
              <DesktopDatePicker {...datePickerProp('TaxExpDte')} />
              <DesktopDatePicker {...datePickerProp('InsExpDte')} />
              <DesktopDatePicker {...datePickerProp('GdInsExpDte')} />
              <DesktopDatePicker {...datePickerProp('AOLExpDte')} />
              <TextField {...dialogDataProp('Rmk')} multiline rows={2} />
              <TagBox value={tagData} setValue={setTagData} tblAbbr="TK" />
            </Box>
          </Paper>
          <Paper sx={{ px: 2, py: 1, flex: 1 }}>
            <BoxFC sx={{ height: "100%", gap: 1 }}>
              <Typography variant="h6" mb={-1} >ข้อมูลไฟล์</Typography>
              <BoxFC height="100%" width="100%">
                <BoxFR flex={1} width="100%">
                  <BoxFC sx={{ gap: 0 }} height="100%" flex={1}>
                    <Typography fontWeight="bold">เอกสารรถ:</Typography>
                    <FileUpload
                      width={270}
                      options={msData.serverData.PreText.TruckFiles || []}
                      fileUploadData={licFileUploadData}
                      setFileUploadData={setLicFileUploadData}
                    />
                  </BoxFC>
                  <BoxFC sx={{ gap: 0 }} height="100%" flex={1}>
                    <Typography fontWeight="bold">ภาษี:</Typography>
                    <FileUpload
                      width={270}
                      options={msData.serverData.PreText.TruckFiles || []}
                      fileUploadData={taxFileUploadData}
                      setFileUploadData={setTaxFileUploadData}
                    />
                  </BoxFC>
                </BoxFR>
                <BoxFR flex={1}>
                  <BoxFC sx={{ gap: 0 }} height="100%" flex={1}>
                    <Typography fontWeight="bold">ประกัน:</Typography>
                    <FileUpload
                      width={270}
                      options={msData.serverData.PreText.TruckFiles || []}
                      fileUploadData={insFileUploadData}
                      setFileUploadData={setInsFileUploadData}
                    />
                  </BoxFC>
                  <BoxFC sx={{ gap: 0 }} height="100%" flex={1}>
                    <Typography fontWeight="bold">พรบ.:</Typography>
                    <FileUpload
                      width={270}
                      options={msData.serverData.PreText.TruckFiles || []}
                      fileUploadData={aolFileUploadData}
                      setFileUploadData={setAOLFileUploadData}
                    />
                  </BoxFC>
                </BoxFR>
              </BoxFC>

            </BoxFC>
          </Paper>

          <Box >
          </Box>
        </BoxFR>
        {
          dialogData.TukId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
        }
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateTruck(dialogData)} />
    </Dialog>
  )
}

export default TruckDialog