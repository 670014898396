export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "FLNNm",
    headerName: "ชื่อ-สกุล",
    width: 160,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    width: 100,
  },
  
]