export const maintenanceApiNm = {
  getAllCheckup: "/maintenance/getAllCheckup",
  getCheckupPlanItem: "/maintenance/getCheckupPlanItem",
  getCheckUpPlanTruck: "/maintenance/getCheckUpPlanTruck",
  insertCheckupCategory: "/maintenance/insertCheckupCategory",
  updateCheckupCategoryCol: "/maintenance/updateCheckupCategoryCol",
  deleteCheckupCategory: "/maintenance/deleteCheckupCategory",
  insertCheckupItem: "/maintenance/insertCheckupItem",
  updateCheckupItemCol: "/maintenance/updateCheckupItemCol",
  deleteCheckupItem: "/maintenance/deleteCheckupItem",
  insertCheckupPln: "/maintenance/insertCheckupPln",
  insertCheckupPlnItemBulk: "/maintenance/insertCheckupPlnItemBulk",
  updateCheckupPlnCol: "/maintenance/updateCheckupPlnCol",
  deleteCheckupPln: "/maintenance/deleteCheckupPln",
  insertCheckupPlanTruckBulk: "/maintenance/insertCheckupPlanTruckBulk",
}