import { BoxFC } from "components/BoxCustom";
import { repairOrderColNm } from "./constant";
import { blue, green, red } from "@mui/material/colors";
import dayjs from "dayjs";

export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "ROTm",
    headerName: repairOrderColNm.ROTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
  {
    field: "DrvNNm",
    headerName: repairOrderColNm.DrvNNm,
    width: 150,
  },
  {
    field: "TukCd",
    headerName: repairOrderColNm.TukCd,
    width: 100,
  },
  {
    field: "OrdHeader",
    headerName: repairOrderColNm.OrdHeader,
    flex: 1,
  },
  {
    field: "ReprDscp",
    headerName: repairOrderColNm.ReprDscp,
    flex: 1,
  },
  {
    field: "StDte",
    headerName: repairOrderColNm.StDte,
    width: 120,
    type: "date",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "EstFinDte",
    headerName: repairOrderColNm.EstFinDte,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: repairOrderColNm.FinDte,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "ROStsNm",
    headerName: repairOrderColNm.ROStsNm,
    width: 150,
    align: "center",
    renderCell: ({row}) => (
      <BoxFC justifyContent="center" alignItems="center" sx={{
        width: 120,
        borderRadius: 4, 
        padding: 1,
        color:"white",
        bgcolor: row.ROStsId===1? red[700]:row.ROStsId ===2? blue[600]: green[600]}}>
          { row.ROStsNm }
      </BoxFC>
    ),
  },
]