
import dayjs from "dayjs";

export const clearFilterData = {
  ROTmSt: null,
  ROTmEn: null,
  DrvId: null,
  TukId: null,
  ROStsId: null
}
export const initFilterData = {
  ...clearFilterData,
  ROTmSt: dayjs().format("YYYY-MM-DD 00:00:00"),
  ROTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
}

export const initDialogData = {
  ROId: null,
  ROTm: null,
  ROStsId: 1,
  DrvId: null,
  TukId: null,
  OrdHdr: "",
  OrdDscp: "",
  ReprDscp: "",
  StDte: null,
  EstFinDte: null,
  FinDte: null,
}