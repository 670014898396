import { AddCircleRounded, DeleteForever, SystemUpdateAltRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback } from "react";
import isFileOverSize from "utils/isFileOverSize";

const ThumbnaiImageList = ({ sx, label = "รูปภาพ", imageItems, onAddImage, onRemoveImage, onImageClick, typId }) => {

  const handleSelectFile = useCallback((e) => {
    const files = Array.from(e.target.files)
    console.log({ files })
    for (const file of files) {
      if (isFileOverSize(file)) return
      const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
      file.label = fileNameOnly
      file.imgLink = URL.createObjectURL(file)
      file.typId = typId
    }
    if (onAddImage) {
      onAddImage(files)
    }
    // setSelectedFiles(o => [...o, ...files])
  }, [onAddImage, typId])

  const handleRemoveFile = useCallback((index) => (e) => {
    e.stopPropagation()
    if (onRemoveImage) {
      onRemoveImage(index)
    }
  }, [onRemoveImage])

  const handleDragOver = useCallback((e) => {
    /** Must prevent the can use onDrop */
    e.preventDefault()
  }, [])

  const handleDropFile = useCallback((e) => {
    e.preventDefault()
    let files = []
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
        files = Array.from(e.dataTransfer.items).map(item => item.getAsFile())
      } else {
        return
      }
    } else {
      if (e.dataTransfer.files.length > 0) {
        files = Array.from(e.dataTransfer.files)
      } else {
        return
      }
    }

    for (const file of files) {
      if (isFileOverSize(file)) return

      // const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
      file.label = file.name
      file.typId = typId
      file.imgLink = URL.createObjectURL(file)
    }

    if (onAddImage) {
      onAddImage(files)
    }
    // setSelectedFiles(o => [...o, ...files])
  }, [onAddImage, typId])

  return (
    <BoxFC sx={{ gap: 0, width: "100%", height: "100%", ...sx }} >
      <BoxFR sx={{ gap: 0 }}>
        <Typography fontWeight="bold">{label}</Typography>
        <Button component="label" sx={{minWidth: 0, px: 1}}>
          <input type="file" hidden onChange={handleSelectFile} />
          <AddCircleRounded color="primary" />
        </Button>
      </BoxFR>
      <Box p={1} overflow="auto"
        bgcolor={blueGrey[50]}
        height="calc(100% - 40px)"
        onDrop={handleDropFile}
        onDragOver={handleDragOver}
      >
        {imageItems.length === 0 ?
          <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%" >
            <SystemUpdateAltRounded sx={{ fontSize: 50, color: grey[500] }} />
            <Typography variant="h6" sx={{ color: grey[500] }}>Drop file here</Typography>
          </BoxFC>
          :
          <BoxFR flexWrap="wrap" height="100%">
            {imageItems.map((item, index) => (
              <Box key={index} sx={{ p: 0.5, bgcolor: "white", borderRadius: 1, height: 115, position: "relative" }}
                onClick={() => { onImageClick(item) }}>
                <img src={item.imgLink} width={"100%"} height={"100%"} alt={item.label} style={{ objectFit: "contain" }} />
                <Box sx={{ position: "absolute", bottom: 4, right: 4, bgcolor: grey[100], opacity: 0.8, borderRadius: 4 }}>
                  <IconButton onClick={handleRemoveFile(index)}>
                    <DeleteForever fontSize="small" sx={{ color: grey[700] }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </BoxFR>
        }
      </Box>
    </BoxFC>
  )

}

export default ThumbnaiImageList;