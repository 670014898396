

import { jsPDF } from "jspdf";
// import { toBahtText } from "utils/toBahtText";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import companyLogo from "branch/resource/company-logo.png"
import { printColumns } from "./columns";


export const printReport = ({ dataTable, aptTm, msData }) => {

  console.log("dataTable::", dataTable)

  const numJobOrd = dataTable.reduce((prev, cur) => prev + +cur.NumJob, 0)
  const MAX_PAGE_SIZE = 20

  const companyData = msData.serverData.CompanyData
  const pageSize = [8.3, 11.7];
  const defaultHeaderSize = 15;
  const startY = 0.3
  const startX = 0.5
  const logoWidth = 0.85
  const logoHeight = 0.65
  const lineHeight = 0.24
  const comapanyAddrSize = 11;
  const contentSize = 13;
  const boxWidth = printColumns.reduce((prev, cur) => prev + cur.width, 0)
  const startBoxX = startX - 0.2
  const linePaddingY = 0.05

  const pl = 0.1

  const doc = new jsPDF({
    orientation: "lanscape",
    unit: "in",
    format: pageSize
  });
  doc.setLineWidth(0.01)

  const totalPage = Math.ceil(dataTable.length / MAX_PAGE_SIZE)
  let rowStart = 0
  let pageNo = 1
  let maxRow = MAX_PAGE_SIZE

  doc.setFont("THSarabun")
  
  do {
    if (pageNo !== 1) {
      doc.addPage(pageSize, "lanscape")
    }

    let yPos = startY
    let xPos = startX

    const img = new Image()
    img.src = companyLogo
    try {
      doc.addImage(img, 'png', xPos, yPos + 0.1, logoWidth, logoHeight)
      console.log("imgWidth::", img.width)
      xPos += logoWidth + 0.2
    } catch (e) {
      console.log("error::", e)
    }
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(defaultHeaderSize)
    yPos += 0.2
    doc.text(companyData.NameEN, xPos, yPos)
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(comapanyAddrSize)
    yPos += lineHeight - 0.05
    doc.text(companyData.AddrEN, xPos, yPos)
    yPos += lineHeight - 0.05
    doc.text(`Tax ID: ${companyData.TaxId}`, xPos, yPos)
    yPos += lineHeight - 0.05
    doc.text(`Tel: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)


    yPos = startY + 0.1
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(22)
    doc.text("รายงานวิ่งรถประจำวัน", 9.5, yPos)

    doc.setFont("THSarabun", "bold")
    doc.setFontSize(contentSize + 2)
    const lineHeightTopRight = lineHeight + lineHeight / 5
    const yPosTopRight = yPos + lineHeightTopRight
    const xPosTopRight = 9.5
    xPos = xPosTopRight
    yPos = yPosTopRight
    doc.text(`ประจำวันที่: ${aptTm}`, xPos, yPos)
    yPos += lineHeight
    doc.text(`จำนวนงาน: ${numJobOrd} เที่ยว`, xPos, yPos)
    yPos += lineHeight

    if (totalPage !== 1) {
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentSize)
      xPos = xPosTopRight
      doc.text("Page: ", xPos, yPos)
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentSize + 2)
      xPos += 0.5
      doc.text(`(${pageNo}/${totalPage})`, xPos, yPos)
    }
    yPos += 0.2

    //----------------- draw header --------------------
    doc.setFillColor("#cce6ff");
    doc.rect(startBoxX, yPos, boxWidth, lineHeight + 0.2, "F")
    doc.line(startBoxX, yPos, startBoxX + boxWidth, yPos)
    doc.line(startBoxX, yPos + lineHeight + 0.2, startBoxX + boxWidth, yPos + lineHeight + 0.2)
    //--------------------------------------------------
    yPos += 0.2
    xPos = startBoxX
    //----------------- print header --------------------
    for (const col of printColumns) {
      doc.text(col.headerName, xPos + (col.width / 2), yPos + linePaddingY, { align: "center" })
      xPos += col.width
    }
    //--------------------------------------------------

    //----------------- print table --------------------
    yPos += lineHeight * 1.7
    const lineTableHeight = lineHeight + 0.05
    const lineAdjust = 0.15
    doc.setLineWidth(0.005)
    for (let i = rowStart; i < Math.min(maxRow, dataTable.length); i++) {
      const row = dataTable[i]
    
      if(i !== rowStart && row.No){
        doc.line(startBoxX, yPos - lineAdjust, startBoxX + boxWidth, yPos - lineAdjust)
      }

      xPos = startBoxX
      if (row.id === 0) {
        doc.setFont("THSarabun", "bold")
        doc.setFillColor("#fff9c4");

        doc.setLineWidth(0.01)
        doc.rect(startBoxX, yPos - lineAdjust, boxWidth, lineHeight + 0.1, "F")
        doc.line(startBoxX, yPos - lineAdjust, startBoxX + boxWidth, yPos- lineAdjust)
        doc.line(startBoxX, yPos - lineAdjust + lineHeight + 0.1, startBoxX + boxWidth, yPos- lineAdjust + lineHeight + 0.1)
        
      }
      for (const col of printColumns) {
        let realXPos = xPos + pl
        if (col.align === "center") {
          realXPos = xPos + (col.width / 2)
        } else if (col.align === "right") {
          realXPos = xPos + col.width - pl
        }
        doc.text(`${row[col.field] || ""}`, realXPos, yPos + linePaddingY, { align: col.align || "left", })
        xPos += col.width
      }
      yPos += lineTableHeight
    }
    //--------------------------------------------------


    pageNo++
    maxRow = pageNo * MAX_PAGE_SIZE
    rowStart = maxRow - MAX_PAGE_SIZE
  } while (pageNo <= totalPage)

  doc.output('dataurlnewwindow');
}