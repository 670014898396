import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { maintenanceApiNm } from "./constant";
import ComboBox from "components/ComboBox";
import { Box, Button, Container, MenuItem, TextField, Tooltip } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { grey } from "@mui/material/colors";
import { columns } from "./columns";
import { alertConfirmDelete, alertError, alertSuccess } from "components/Alert";
import TextDialog from "./TextDialog";
import { AddRounded, DeleteRounded, EditRounded, LinkRounded, SaveRounded } from "@mui/icons-material";
import PlanDialog from "./PlanDialog";
import { sortObj } from "utils";
import LinkPlanDialog from "./LinkPlanDialog";

let title = ""
let oldText = ""
let oldVersion = ""
let selectedRow = null
let mode = ""
let originalData = {}

const initChkTypId = 1;

const CheckupManagement = () => {
  const { ax } = useContext(GlobalStateContext)


  const [chkTypCombo, setChkTypCombo] = useState([]);
  const [selectedChkTypId, setSelectedChkTypId] = useState("");
  const [chkPlnCombo, setChkPlnCombo] = useState([]);
  const [selectedChkPlnId, setSelectedChkPlnId] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [addCatText, setAddCatText] = useState("")
  const [dialogTextOpen, setDialogTextOpen] = useState(false)
  const [dialogPlanOpen, setDialogPlanOpen] = useState(false)
  const [dialogLinkPlanOpen, setDialogLinkPlanOpen] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])

  const mergeCatAndItem = useCallback((cats, items, typeId) => {
    const result = []
    let catIndex = 0
    const catFilteredType = cats.filter(cat => cat.MtChkTypId === typeId)
    for (const cat of catFilteredType) {
      catIndex++
      result.push({
        ...cat,
        No: `${catIndex}.`,
        id: cat.MtChkCatId.toString(),
        isCatRow: true
      })
      const cloneCatIndex = catIndex

      result.push(...items
        .filter(item => item.MtChkCatId === cat.MtChkCatId)
        .map((item, index) => ({
          ...item,
          No: `${cloneCatIndex}.${index + 1}`,
          id: `${cloneCatIndex}.${item.MtChkItmId}`,
          isCatRow: false
        })))
    }
    return result
  }, [])

  const setTable = useCallback((data, typeId) => {
    if (typeId === null) return

    const { mtCheckupCats, mtCheckupItems, mtCheckupPlns } = data
    const result = mergeCatAndItem(mtCheckupCats, mtCheckupItems, typeId)
    setDataTable(result)
    if (mtCheckupPlns) {
      setChkPlnCombo(mtCheckupPlns
        .filter(item => item.MtChkTypId === typeId)
        .map(item => ({
          id: item.MtChkPlnId,
          label: `${item.MtChkPlnNm} (${item.Vers})`,
          MtChkPlnNm: item.MtChkPlnNm,
          Vers: item.Vers
        })))
    }
  }, [mergeCatAndItem])

  const handleAddCategory = useCallback(() => {
    if (!selectedChkTypId) {
      alertError("กรุณาเลือกประเภทการตรวจเช็คก่อน")
      return
    }
    if (!addCatText) {
      alertError("กรุณากรอกหัวข้อก่อน")
      return
    }

    const postData = {
      MtChkTypId: selectedChkTypId,
      MtChkCatNm: addCatText,
      IsActv: 1,
      Prty: 1000
    }
    ax.post(maintenanceApiNm.insertCheckupCategory, postData).then(value => {
      if (value.data) {
        setTable(value.data, selectedChkTypId)
        setAddCatText("")
      }
    })
  }, [ax, selectedChkTypId, addCatText, setTable])

  const handleAddItem = useCallback((row) => {
    console.log('in handleAddItem row::', row)
    oldText = ""
    selectedRow = row
    mode = "add"
    title = "เพิ่มรายการ"
    setDialogTextOpen(true)
  }, [])

  const handleEdit = useCallback((row) => {
    console.log('handleEdit row::', row)
    selectedRow = row
    mode = "edit"
    title = row.isCatRow ? "แก้ไขหัวข้อ" : "แก้ไขรายการ"
    oldText = row.isCatRow ? row.MtChkCatNm : row.MtChkItmNm
    setDialogTextOpen(true)

  }, [])

  const handleAddPlan = useCallback(() => {
    oldText = ""
    oldVersion = ""
    selectedRow = null
    mode = "add"
    title = "เพิ่มแบบฟอร์ม"
    setDialogPlanOpen(true)
  }, [])

  const handleEditPlan = useCallback(() => {
    const pln = chkPlnCombo.find(item => item.id === selectedChkPlnId)
    oldText = pln.MtChkPlnNm
    oldVersion = pln.Vers
    selectedRow = null
    mode = "edit"
    title = "แก้ไขแบบฟอร์ม"
    setDialogPlanOpen(true)
  }, [chkPlnCombo, selectedChkPlnId])

  const handleDeletePln = useCallback(() => {
    alertConfirmDelete(() => {
      ax.post(maintenanceApiNm.deleteCheckupPln, { MtChkPlnId: selectedChkPlnId }).then(value => {
        if (value.data) {
          setTable(value.data, selectedChkTypId)
        }
      })
    })
  }, [selectedChkPlnId, ax, setTable, selectedChkTypId])

  const handleToogleIsActv = useCallback((row) => {
    let apiNm = ""
    let postData = {}
    if (row.isCatRow) {
      apiNm = maintenanceApiNm.updateCheckupCategoryCol
      postData = {
        MtChkCatId: row.MtChkCatId,
        LogRmk: "แก้ไข IsActv",
        ColValues: { IsActv: row.IsActv ? 0 : 1 }
      }
    } else {
      apiNm = maintenanceApiNm.updateCheckupItemCol
      postData = {
        MtChkItmId: row.MtChkItmId,
        LogRmk: "แก้ไข IsActv",
        ColValues: { IsActv: row.IsActv ? 0 : 1 }
      }
    }
    ax.post(apiNm, postData).then(value => {
      if (value.data) {
        setTable(value.data, selectedChkTypId)
      }
    })
  }, [ax, setTable, selectedChkTypId])

  const handleDelete = useCallback((row) => {
    alertConfirmDelete(() => {
      let apiNm = ""
      let postData = {}
      if (row.isCatRow) {
        apiNm = maintenanceApiNm.deleteCheckupCategory
        postData = { MtChkCatId: row.MtChkCatId }
      } else {
        apiNm = maintenanceApiNm.deleteCheckupItem
        postData = { MtChkItmId: row.MtChkItmId }
      }

      ax.post(apiNm, postData).then(value => {
        if (value.data) {
          setTable(value.data, selectedChkTypId)
        }
      })
    })

  }, [ax, setTable, selectedChkTypId])

  const handleUp = useCallback((row) => {
    let updatedRows = [...dataTable];
    const rowIndex = updatedRows.findIndex(item => item.id === row.id);

    if (rowIndex === 0) return
    if (row.isCatRow) {
      //find above category row
      for (let i = rowIndex - 1; i >= 0; i--) {
        if (updatedRows[i].isCatRow) {
          const previousCategory = updatedRows[i];
          const categoryRows = updatedRows.filter(item => item.MtChkCatId === row.MtChkCatId);
          updatedRows = updatedRows.filter(item => item.MtChkCatId !== row.MtChkCatId);
          const previousCategoryIndex = updatedRows.findIndex(item => item.id === previousCategory.id);
          updatedRows.splice(previousCategoryIndex, 0, ...categoryRows);
          setDataTable(updatedRows);
          break;
        }
      }
    } else {
      const categoryRow = updatedRows.find(item => item.MtChkCatId === row.MtChkCatId && item.isCatRow);
      const catRowIndex = updatedRows.findIndex(item => item.id === categoryRow.id);
      if (catRowIndex < rowIndex - 1) {
        const previousRowIndex = rowIndex - 1;
        [updatedRows[rowIndex], updatedRows[previousRowIndex]] = [updatedRows[previousRowIndex], updatedRows[rowIndex]];
      }
    }
    setDataTable(updatedRows);
  }, [dataTable]);

  const handleDown = useCallback((row) => {
    let updatedRows = [...dataTable];
    const rowIndex = updatedRows.findIndex(item => item.id === row.id);

    if (rowIndex === updatedRows.length - 1) return
    //find below category row
    let nextCategory = null
    for (let i = rowIndex + 1; i < updatedRows.length; i++) {
      if (updatedRows[i].isCatRow) {
        nextCategory = updatedRows[i];
        break;
      }
    }
    if (row.isCatRow) {
      if (!nextCategory) {
        return
      }
      const categoryRows = updatedRows.filter(item => item.MtChkCatId === row.MtChkCatId);
      //remove all rows of this category
      updatedRows = updatedRows.filter(item => item.MtChkCatId !== row.MtChkCatId);
      const nextCategoryIndex = updatedRows.findIndex(item => item.id === nextCategory.id);
      const numNexCat = updatedRows.filter(item => item.MtChkCatId === nextCategory.MtChkCatId).length;
      const nextCategoryIndexWithOffset = nextCategoryIndex + numNexCat;
      updatedRows.splice(nextCategoryIndexWithOffset, 0, ...categoryRows);
      setDataTable(updatedRows);
    } else {
      let endIndex = updatedRows.length - 1;
      if (nextCategory) {
        endIndex = updatedRows.findIndex(item => item.id === nextCategory.id);
      }
      if (endIndex > rowIndex + 1) {
        const nextRowIndex = rowIndex + 1;
        [updatedRows[rowIndex], updatedRows[nextRowIndex]] = [updatedRows[nextRowIndex], updatedRows[rowIndex]];
      }
    }
    setDataTable(updatedRows);
  }, [dataTable]);

  const onFinish = useCallback((text, row) => {
    let apiNm = ""
    let postData = {}
    if (mode === "add") {
      postData = {
        MtChkCatId: row.MtChkCatId,
        MtChkItmNm: text,
        IsActv: 1,
        Prty: 1000
      }
      apiNm = maintenanceApiNm.insertCheckupItem
    } else if (row.isCatRow) {
      apiNm = maintenanceApiNm.updateCheckupCategoryCol
      postData = {
        MtChkCatId: row.MtChkCatId,
        LogRmk: "แก้ไขหัวข้อ",
        ColValues: { MtChkCatNm: text }
      }
    } else {
      apiNm = maintenanceApiNm.updateCheckupItemCol
      postData = {
        MtChkItmId: row.MtChkItmId,
        LogRmk: "แก้ไขรายการ",
        ColValues: { MtChkItmNm: text }
      }
    }
    ax.post(apiNm, postData).then(value => {
      if (value.data) {
        setTable(value.data, selectedChkTypId)
      }
    })
  }, [ax, setTable, selectedChkTypId])

  const onPlanDialogFinish = useCallback((text, version) => {
    let postData = {}
    let apiNm = ""
    if (mode === "add") {

      postData = {
        MtChkPlnNm: text,
        Vers: version,
        MtChkTypId: selectedChkTypId,
      }
      apiNm = maintenanceApiNm.insertCheckupPln
    } else {
      postData = {
        MtChkPlnId: selectedChkPlnId,
        LogRmk: "แก้ไขชื่อและ version",
        ColValues: {
          MtChkPlnNm: text,
          Vers: version,
        }
      }
      apiNm = maintenanceApiNm.updateCheckupPlnCol
    }
    ax.post(apiNm, postData).then(value => {
      if (value.data) {
        //function have to reuturn all data coz have to set plan combo
        originalData = value.data
        setTable(value.data, selectedChkTypId)
      }
    })
  }, [ax, selectedChkPlnId, selectedChkTypId, setTable])

  const handleTypeChange = useCallback((e) => {
    setSelectedChkTypId(e.target.value)
    setSelectedChkPlnId(null)
    setTable(originalData, e.target.value)
  }, [setTable])

  const setPlanItem = useCallback((data) => {

    const { mtCheckupCats, mtCheckupItems } = originalData
    const result = mergeCatAndItem(mtCheckupCats, mtCheckupItems, selectedChkTypId)

    const itmPrty = data.reduce((prev, cur) => ({ ...prev, [cur.MtChkItmId]: cur.Prty }), {})

    let catPrty = 0;
    const newSelectionModel = []
    for (let i = 0; i < result.length; i++) {
      if (result[i].isCatRow) {
        if (i + 1 < result.length && !result[i + 1].isCatRow) {
          catPrty = itmPrty[result[i + 1].MtChkItmId] || 9999
          result[i].Prty = catPrty
          result[i].CatPrty = catPrty
          if (catPrty !== 9999) {
            newSelectionModel.push(result[i].id)
          }
        }
        else {
          result[i].Prty = 9999
          result[i].CatPrty = 9999
        }
      } else {
        result[i].Prty = itmPrty[result[i].MtChkItmId] || 9999
        result[i].CatPrty = catPrty
        if (result[i].Prty !== 9999) {
          newSelectionModel.push(result[i].id)
        }
      }
    }

    sortObj(result, "CatPrty", "Prty")
    let catIndex = 0
    let index = 0
    for (const item of result) {
      if (item.isCatRow) {
        catIndex++
        item.No = `${catIndex}.`
        index = 0
      } else {
        index++
        item.No = `${catIndex}.${index}`
      }
    }
    setDataTable(result)
    setSelectionModel(newSelectionModel)
  }, [mergeCatAndItem, selectedChkTypId])

  const handleChkPlnChange = useCallback((id) => {
    setSelectedChkPlnId(id)
    if (!id) {
      console.log('originalData::', originalData)
      setTable(originalData, selectedChkTypId)
      setSelectionModel([])
    } else {
      ax.post(maintenanceApiNm.getCheckupPlanItem, { MtChkPlnId: id }).then(value => {
        if (value.data) {
          setPlanItem(value.data)
        }
      })
    }
  }, [ax, setTable, selectedChkTypId, setPlanItem])

  const handleSelectionModelChange = useCallback((ids) => {
    setSelectionModel(oIds => {
      const newlyAdded = ids.filter(id => !oIds.includes(id));
      const newlyDeselected = oIds.filter(id => !ids.includes(id));
      console.log('newlyAdded::', newlyAdded)
      console.log('newlyDeselected::', newlyDeselected)
      const clickedRow = dataTable.find(row => row.id === newlyAdded[0] || row.id === newlyDeselected[0]);

      if (!clickedRow) return ids
      if (clickedRow.isCatRow) {
        const catItmIds = dataTable
          .filter(row => row.MtChkCatId === clickedRow.MtChkCatId && row.IsActv)
          .map(row => row.id)
        if (newlyAdded.length > 0) {
          return [...new Set([...ids, ...catItmIds])]
        } else {
          return ids.filter(id => !catItmIds.includes(id))
        }
      }
      return ids
    })
  }, [dataTable])

  const handleSavePlan = useCallback(() => {
    dataTable.forEach((row, index) => { row.Prty = index })
    const postData = {
      MtChkPlnId: selectedChkPlnId,
      getArgs: { MtChkPlnId: selectedChkPlnId }
    }
    if (selectedChkPlnId) {
      postData.argsItmArr = dataTable
        .filter(row => selectionModel.includes(row.id) && !row.isCatRow)
        .map((row, index) => ({
          MtChkPlnId: selectedChkPlnId,
          MtChkItmId: row.MtChkItmId,
          Prty: row.Prty
        }))
    } else {
      postData.argsItmArr = dataTable
        .filter(row => !row.isCatRow)
        .map(row => ({
          MtChkItmId: row.MtChkItmId,
          Prty: row.Prty
        }))
      postData.argsCatArr = dataTable
        .filter(row => row.isCatRow)
        .map(row => ({
          MtChkCatId: row.MtChkCatId,
          Prty: row.Prty
        }))
    }
    ax.post(maintenanceApiNm.insertCheckupPlnItemBulk, postData).then(value => {
      if (value.data) {
        if (!selectedChkPlnId) {
          originalData = value.data
          setTable(value.data, selectedChkTypId)
        } else {
          setPlanItem(value.data)
        }
        alertSuccess("บันทึกเรียบร้อยแล้ว")
      }
    })
  }, [ax, dataTable, selectionModel, selectedChkPlnId, selectedChkTypId, setTable, setPlanItem])

  const handleLinkPlan = useCallback(() => {
    if (!selectedChkPlnId) {
      alertError("กรุณาเลือกแบบฟอร์มก่อน")
      return
    }
    setDialogLinkPlanOpen(true)
  }, [selectedChkPlnId])

  useEffect(() => {
    ax.post(maintenanceApiNm.getAllCheckup, {}).then(value => {
      if (value.data) {
        originalData = value.data
        setChkTypCombo(value.data.mtCheckupTypes
          .map(item => ({
            id: item.MtChkTypId,
            label: item.MtChkTypNm
          })))
        setSelectedChkTypId(initChkTypId)
        setTable(value.data, initChkTypId)
      }
    })
  }, [ax, setTable])
  console.log('dataTable::', dataTable)
  console.log("selectionModel::", selectionModel)
  return (
    <Container sx={{height: "100%"}}>
      <BoxFC height="100%" width="100%" sx={{ alignItems: "center" }}>
        <BoxFR width="100%">
          <TextField sx={{ width: 180 }} size="small" label="ประเภทการตรวจเช็ค" select
            value={selectedChkTypId}
            onChange={handleTypeChange}>
            {chkTypCombo.map((item) => (
              <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
            ))}
          </TextField>
          {/* <ComboBox sx={{ width: 180 }} options={chkTypCombo} label="ประเภทการตรวจเช็ค"
          selectedId={selectedChkTypId}
          setSelectedId={handleTypeChange}
        /> */}
          <TextField label="หัวข้อ" size="small" sx={{ width: 220 }}
            value={addCatText}
            onChange={(e) => setAddCatText(e.target.value)} />
          <Button variant="contained" onClick={handleAddCategory}>เพิ่ม</Button>
          <Box flex={1} />
          <ComboBox sx={{ width: 250 }} options={chkPlnCombo} label="แบบฟอร์มการตรวจเช็ค"
            selectedId={selectedChkPlnId}
            setSelectedId={handleChkPlnChange} />
          <Tooltip title="เพิ่มแบบฟอร์ม" followCursor>
            <Button variant="contained"
              sx={{ minWidth: 0, borderRadius: 8, width: 36 }}
              onClick={handleAddPlan}>
              <AddRounded />
            </Button>
          </Tooltip>
          <Tooltip title="แก้ไขแบบฟอร์ม" followCursor>
            <Button variant="contained"
              sx={{ minWidth: 0, borderRadius: 8, width: 36 }}
              onClick={handleEditPlan}>
              <EditRounded />
            </Button>
          </Tooltip>
          <Tooltip title="ลบแบบฟอร์ม" followCursor>
            <Button variant="contained"
              sx={{ minWidth: 0, borderRadius: 8, width: 36 }}
              onClick={handleDeletePln}>
              <DeleteRounded />
            </Button>
          </Tooltip>
          <Tooltip title="Link แบบฟอร์ม กับรถ" followCursor>
            <Button variant="contained"
              sx={{ minWidth: 0, borderRadius: 8, width: 36 }}
              onClick={handleLinkPlan}>
              <LinkRounded />
            </Button>
          </Tooltip>
        </BoxFR>
        <Box flex={1} width="100%" sx={{
          "& .category-row": { fontWeight: "bold" },
          "& .inactv-row": { color: grey[400] }
        }}>

          <DataGridCellExpand
            // rowReordering
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            density="compact"
            disableSelectionOnClick
            hideFooter
            rows={dataTable}
            columns={columns({ handleAddItem, handleEdit, handleDelete, handleToogleIsActv, handleUp, handleDown })}
            getRowClassName={(params) => params.row.IsActv ? params.row.isCatRow ? "category-row" : "" : "inactv-row"}
          // onRowOrderChange={handleRowOrderChange}
          />
        </Box>
        <BoxFR>
          <Box flex={1} />
          <Button variant="contained"
            sx={{ minWidth: 0, width: 350 }}
            onClick={handleSavePlan}>
            <SaveRounded sx={{ mr: 1 }} />
            บันทึกลำดับและตัวเลือกแบบฟอร์ม
          </Button>
        </BoxFR>
        <TextDialog
          title={title}
          oldText={oldText}
          row={selectedRow}
          dialogOpen={dialogTextOpen}
          setDialogOpen={setDialogTextOpen}
          onFinish={onFinish} />
        <PlanDialog
          title={title}
          oldText={oldText}
          oldVersion={oldVersion}
          dialogOpen={dialogPlanOpen}
          setDialogOpen={setDialogPlanOpen}
          onFinish={onPlanDialogFinish} />
        <LinkPlanDialog
          mtChkPlnId={selectedChkPlnId}
          title={selectedChkPlnId ? chkPlnCombo.find(item => item.id === selectedChkPlnId)?.label : ""}
          dialogOpen={dialogLinkPlanOpen}
          setDialogOpen={setDialogLinkPlanOpen} />
      </BoxFC>

    </Container>
  );
}

export default CheckupManagement;
