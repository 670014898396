import React, { useCallback, useMemo, useState, useContext } from "react";
import { Box, Dialog, DialogContent, TextField, Avatar, Button, FormControlLabel, Checkbox, MenuItem } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData, driverColNm, driverApiNm } from 'constants/driverConstant';
import useDialogOpen from "hooks/useDialogOpen";
import ComboBox from "components/ComboBox";
import { loadMSData, validate } from "utils";
import { truckColNm } from "constants/truckConstant";
import CardImage from 'components/CardImage';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FOLDER_DRIVER_IMAGE } from 'constants/serverConstant';
import { GlobalStateContext } from "contexts/GlobalStateContext";
import PaperComponent from "components/PaperComponent";
import { alertConfirmDelete, alertError, alertWarning } from "components/Alert";
import dayjs from "dayjs";
import TagBox from "components/TagBox";
// import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import { mobileApiNm } from "constants/mobileConstant";

let originalTagIds = []
let originalDialogData = null
let originalFileData = null
const DriverDialog = ({ state, fn }) => {
  const { dialogOpen, selectedId, lastFilterData } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const [fileData, setFileData] = useState({
    pfImg: null,
    drvLicImg: null,
    citzImg: null,
    pfImgPv: null,
    drvLicImgPv: null,
    citzImgPv: null,
    pfImgFile: null,
    drvLicImgFile: null,
    citzImgFile: null
  })
  const { ax, msData, setMSData } = useContext(GlobalStateContext);
  const [tagData, setTagData] = useState([])

  const modifyData = useCallback((dialogData) => {
    const baseUrl = window.location.origin;
    originalDialogData = { ...dialogData }
    // dialogData.pfImgPv = dialogData.PfImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.PfImg}`
    // dialogData.drvLicImgPv = dialogData.DrvLicImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.DrvLicImg}`
    // dialogData.citzImgPv = dialogData.CitzImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.CitzImg}`
    originalFileData = {
      PfImg: dialogData.PfImg,
      DrvLicImg: dialogData.DrvLicImg,
      CitzImg: dialogData.CitzImg,
      pfImgPv: dialogData.PfImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.PfImg}`,
      drvLicImgPv: dialogData.DrvLicImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.DrvLicImg}`,
      citzImgPv: dialogData.CitzImg && `${baseUrl}/${FOLDER_DRIVER_IMAGE}${dialogData.DrvId}/${dialogData.CitzImg}`
    }
    originalTagIds = dialogData.Tags.map(item => ({ id: item.TagId, label: item.Name }))
    setTagData(originalTagIds)
    setFileData(originalFileData)
    return dialogData
  }, [])

  useDialogOpen(dialogOpen, selectedId, driverApiNm.getDriver, "DrvId", setDialogData, initDialogData, modifyData)


  const validation = useMemo(() => ({
    FName: dialogData.FName === '',
    LName: dialogData.LName === '',
    NName: dialogData.NName === '',
    Tel: dialogData.Tel === '',
    CitzId: dialogData.CitzId === '',
    WrkDteSt: dialogData.WrkDteSt === null || !dayjs(dialogData.WrkDteSt).isValid(),
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: driverColNm[name],
    value: dialogData[name] || "",
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const checkAndUploadImage = useCallback(async (colNm, pvNm, fileNm, fileData, drvId) => {
    if (fileData[fileNm] || (fileData[pvNm] == null && fileData[colNm])) {
      const data = new FormData();
      data.append('image', fileData[fileNm]);
      data.append('DrvId', drvId);
      data.append('colNm', colNm);
      await ax.post(driverApiNm.uploadImage, data)
    }
  }, [ax])

  const insertUpdateDriver = useCallback(async (dialogData) => {
    let hasChange = false;
    let success = true;

    let result;
    const isInsert = dialogData.DrvId === 0;
    if (JSON.stringify(originalDialogData) !== JSON.stringify(dialogData)) {
      hasChange = true
      let url = "";
      const postData = { ...dialogData, getArgs: lastFilterData }
      if (isInsert) {
        url = driverApiNm.insertDriver
      } else {
        url = driverApiNm.updateDriver
      }
      result = await ax.post(url, postData);

      if (result.data) {
        if (isInsert) dialogData.DrvId = result.data
      } else {
        success = false
      }
    }

    /**
     * file dont need check hasChange coz no effect to dataTable
     */
    await checkAndUploadImage('PfImg', 'pfImgPv', 'pfImgFile', fileData, dialogData.DrvId)
    await checkAndUploadImage('CitzImg', 'citzImgPv', 'citzImgFile', fileData, dialogData.DrvId)
    await checkAndUploadImage('DrvLicImg', 'drvLicImgPv', 'drvLicImgFile', fileData, dialogData.DrvId)

    if (JSON.stringify(originalTagIds) !== JSON.stringify(tagData)) {
      hasChange = true
      const tagResult = await ax.post(driverApiNm.updaetDriverTag, { DrvId: dialogData.DrvId, TagIds: tagData.map(item => item.id) })
      if (!tagResult.data) success = false
    }
    if (result && Array.isArray(result.Data)) {
      onFinish(result.data)
    } else {
      if (hasChange)
        onFinish(null)
    }
    if (hasChange) {
      loadMSData(ax, setMSData, null)
    }
    if (success) {
      setDialogOpen(false)
    }
  }, [ax, checkAndUploadImage, setMSData, lastFilterData, onFinish, setDialogOpen, tagData, fileData])


  const deleteDriver = useCallback((drvId) => {
    alertConfirmDelete(() => {
      ax.post(driverApiNm.deleteDriver, { DrvId: drvId }).then(value => {
        if (value.data) {
          onFinish(value.data);
          setDialogOpen(false);
          loadMSData(ax, setMSData, null)
        }
      })
    })
  }, [ax, setMSData, onFinish, setDialogOpen])

  const isFileOverSize = useCallback((file) => {
    if (file.size > (4.8 * 1024 * 1024)) {
      alertError("ไฟล์ ใหญ่เกิน 5MB ไม่สามารถเพิ่มได้")
      return true
    } else {
      return false
    }
  }, [])

  const handleFileChange = useCallback((fileNm, pvNm, file) => {
    if (file && isFileOverSize(file)) return
    setFileData((oldState) => (
      {
        ...oldState,
        [fileNm]: file,
        [pvNm]: file ? URL.createObjectURL(file) : null
      }
    ))
  }, [isFileOverSize])

  const cardImageProp = useCallback((label, pvName, fileName) => ({
    width: 200,
    height: 150,
    name: label,
    imgLink: fileData[pvName],
    onImageChange: (e) => { handleFileChange(fileName, pvName, e.target.files[0]) },
    onDelete: (e) => { handleFileChange(fileName, pvName, null) }
  }), [fileData, handleFileChange])


  const dialogCheckBoxProp = useCallback((name) => ({
    name: name,
    checked: dialogData[name] ? true : false,
    onChange: (e) => { setDialogData((oldState => ({ ...oldState, [name]: e.target.checked ? 1 : 0 }))) }
  }), [dialogData])


  const datePickerProp = (name) => ({
    label: driverColNm[name],
    inputFormat: 'DD/MM/YYYY',
    mask: '__/__/____',
    value: dialogData[name] || '',
    onChange: (newValue) => { setDialogData((oldState) => ({ ...oldState, [name]: newValue || null })) },
    renderInput: (params) => <TextField size='small' {...params} required />
  })

  const handleUnlinkMobile = useCallback(()=>{
    alertConfirmDelete("มือถือจะไม่สามารถเข้าสู่ระบบได้ ต้องการดำเนินการต่อหรือไม่?", ()=>{
      ax.post(mobileApiNm.deleteDriverMobile, {DrvId: selectedId}).then(value=>{
        if(value.data){
          setDialogOpen(false);
          onFinish();
        }
      })
    })
  }, [ax, onFinish, selectedId, setDialogOpen])

  const handleSelectTruck = useCallback((id) => {
    const found = msData.drivers.find(drv=>drv.TukId === id)
    if(found){
      alertWarning(`คำเตื่อน: รถนี้มี "${found.FName} ${found.LName}(${found.NName})" ขับอยู่แล้ว`)
    }
    setDialogData(oldState => ({ ...oldState, TukId: id }))
  }, [msData.drivers])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขพนักงานขับรถ" : "เพิ่มพนักงานขับรถ"}
        onDeleteClick={selectedId > 0 ? () => deleteDriver(selectedId) : null}
      >
        {dialogData.HasDrvMob &&
          <Button sx={{ ml: 1 }} variant="contained" color="secondary" onClick={handleUnlinkMobile}>
            <MobileOffIcon sx={{ mr: 1 }} />ยกเลิกการเชื่อมต่อมือถือ
          </Button>}
      </DialogHeader>
      <DialogContent>
        <Box display='flex' flexDirection='row' mt={2} gap={2}>
          <Box display='flex' flexDirection='column' gap={2} width={250} alignItems='center'>
            <Box position='relative'>
              <Avatar
                alt={dialogData.FName && `${dialogData.FName} ${dialogData.LName} (${dialogData.NName})`}
                src={fileData.pfImgPv}
                style={{ width: 200, height: 200 }} />
              <Button component='label' variant='contained' color='primary' sx={{ position: 'absolute', left: 0, bottom: 0, minWidth: 20, padding: 0.5 }}>
                <ModeEditOutlineRoundedIcon />
                <input type='file' onChange={(e) => handleFileChange("pfImgFile", "pfImgPv", e.target.files[0])} hidden />
              </Button>
              <Button variant='contained' color='error' sx={{ position: 'absolute', right: 0, bottom: 0, minWidth: 20, padding: 0.5 }}
                onClick={(e) => handleFileChange("pfImgFile", "pfImgPv", null)}>
                <DeleteForeverRoundedIcon />
              </Button>
            </Box>
            <CardImage {...cardImageProp('บัตรประชาชน', 'citzImgPv', 'citzImgFile')} />
            <CardImage {...cardImageProp('ใบขับขี่', 'drvLicImgPv', 'drvLicImgFile')} />
          </Box>
          <Box display='flex' flexGrow={1} flexDirection='column' gap={1} >
            <Box display='flex' flexDirection='row'>
              <FormControlLabel control={<Checkbox {...dialogCheckBoxProp('IsActv')} />} label={driverColNm.IsActv} />
              {/* <FormControlLabel control={<Checkbox {...dialogCheckBoxProp('IsSub')} />} label={driverColNm.IsSub} /> */}
            </Box>
            <TextField {...dialogDataProp('FName')} required />
            <TextField {...dialogDataProp('LName')} required />
            <TextField {...dialogDataProp('NName')} required />
            <TextField {...dialogDataProp('Tel')} required />
            <TextField {...dialogDataProp('CitzId')} required />
            <TextField {...dialogDataProp('LicTyp')} required select>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </TextField>
            <TextField {...dialogDataProp('Addr')} multiline rows={3} />
            <DesktopDatePicker {...datePickerProp('WrkDteSt')} />
            <ComboBox options={msData.truckCombo} label={truckColNm.Code}
              selectedId={dialogData.TukId}
              setSelectedId={handleSelectTruck}
            />
            <TagBox value={tagData} setValue={setTagData} tblAbbr="DV" />
            <TextField {...dialogDataProp('BkAccNo')} />
            <TextField {...dialogDataProp('Rmk')} />
            {
              dialogData.DrvId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
            }
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateDriver(dialogData)} />
    </Dialog>
  )
}

export default React.memo(DriverDialog)