import { Dialog, DialogContent, TextField} from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import SearchButton from "./buttons/SearchButton"
import { cusColNm } from "constants/cusConstant"
import { coreApiNm } from "constants/serverConstant"
import { alertError } from "./Alert"

const initDialogData = {
  TaxId: '',
  Name: '',
  Addr: '',
}
const SearchCompanyAddrDialog = ({ dialogOpen, setDialogOpen, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })

  const textFielProps = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: cusColNm[name],
    value: dialogData[name] || "",
    onChange: (e) => { setDialogData((o) => ({ ...o, [name]: e.target.value })) }
  }), [dialogData])

  const handleSearch = useCallback(() => {
    ax.post(coreApiNm.getCompanyAddress, { TaxId: dialogData.TaxId }).then(value => {
      if (value.data) {
        if (value.data.status?.code === "1004") {
          alertError("ไม่พบข้อมูลเลขประจำตัวผู้เสียภาษีนี้")
          return
        } else if (value.data.status?.code === "1000") {
          const dataArr = value.data.data
          if (!dataArr.length || dataArr.length === 0) {
            alertError("ไม่สามารถทำรายการได้ ข้อมูลไม่ครบถ้วน")
            return
          }
          const data = dataArr[0]
          const name = data["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicNameTH"]
          const addrObj = data["cd:OrganizationJuristicPerson"]["cd:OrganizationJuristicAddress"]["cr:AddressType"]
          const provCd = addrObj["cd:CountrySubDivision"]["cr:CountrySubDivisionCode"]
          console.log(data, name, provCd, addrObj)
          let tWord = ""
          let aWord = ""
          let pWord = "จ."
          if(provCd === "TH-10"){
            tWord = "แขวง"
            aWord = "เขต"
          } else {
            tWord = "ต."
            aWord = "อ."
          }
          tWord += addrObj["cd:CitySubDivision"]["cr:CitySubDivisionTextTH"]
          aWord += addrObj["cd:City"]["cr:CityTextTH"]
          pWord += addrObj["cd:CountrySubDivision"]["cr:CountrySubDivisionTextTH"]
          console.log(tWord, aWord, pWord)
          const addr = `${addrObj["cd:Address"]} ${tWord} ${aWord} ${pWord}`
          setDialogData(o=>({...o, Name: name, Addr: addr}))
        } else {
          alertError("เกิดข้อผิดพลาดอื่นๆ")
          return
        }
      }
    })
  }, [dialogData, ax])

  const handleOk = useCallback(() => {
    onFinish(dialogData)
    setDialogOpen(false)
  }, [dialogData, onFinish, setDialogOpen])

  useEffect(()=>{
    if(!dialogOpen){
      setDialogData({...initDialogData})
    }
  }, [dialogOpen])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'

    // PaperProps={{
    //   style: {
    //     minHeight: '90%',
    //     maxHeight: '90%',
    //   }
    // }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ค้นหาที่อยู่บริษัท"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <BoxFR>
            <TextField {...textFielProps("TaxId")} />
            <SearchButton onClick={handleSearch} />
          </BoxFR>
          <TextField {...textFielProps("Name")} />
          <TextField {...textFielProps("Addr")} multiline rows={4} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ใช้ที่อยู่นี้"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOk} />
    </Dialog>
  )
}

export default React.memo(SearchCompanyAddrDialog)