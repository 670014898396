import {  Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useCallback, useEffect, useState } from "react"

const TextDialog = ({ title, oldText, row, dialogOpen, setDialogOpen, onFinish }) => {

  const [text, setText] = useState("")
  
  const handleDialogOK = useCallback(() => {
    onFinish(text, row)
    setDialogOpen(false)
  }, [text, onFinish, row, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      if(oldText) setText(oldText)
    } else {
      setText("")
    } 
  }, [dialogOpen, oldText])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <TextField 
            autoFocus size="small" fullWidth 
            value={text} 
            onChange={(e)=>{setText(e.target.value)}} 
            onKeyUp={(e) =>  { if (e.code === "Enter" || e.code === "NumpadEnter") { handleDialogOK() } }}/>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(TextDialog)