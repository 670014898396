import React, { useCallback, useRef, useState, useMemo, useContext, useEffect } from "react";

import { GoogleMap, useJsApiLoader, Marker, Circle, Polygon } from '@react-google-maps/api';
import { Button, InputAdornment, TextField, Tooltip } from "@mui/material";
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import { BoxFC, BoxFR } from "components/BoxCustom";
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { BrowserNotSupportedRounded, LocationOffRounded } from "@mui/icons-material";

const containerStyle = {
  width: '100%',
  height: '100%'
};

export const LAEMCHABANG_OUT_GATE_LOC = { lat: 13.087725, lng: 100.894545 }
export const LAEMCHABANG_SEA_PORT = { lat: 13.072172, lng: 100.903980 }

let defaultZoom = 13
const GoogleMapWrapper = ({ latLngData, setLatLngData, initCenter }) => {
  const [map, setMap] = useState(null)
  const { msData } = useContext(GlobalStateContext)
  const [isPinInBound, setIsPinInBound] = useState(false)


  const modInitCenter = useMemo(() => {
    defaultZoom = 13
    if (initCenter) {
      defaultZoom = 16
      return initCenter
    }
    if (msData.serverData.CompanyData.Lat && msData.serverData.CompanyData.Lng) {
      return {
        lat: +msData.serverData.CompanyData.Lat,
        lng: +msData.serverData.CompanyData.Lng
      }
    }
    return LAEMCHABANG_SEA_PORT
  }, [msData.serverData.CompanyData, initCenter])

  const lngRef = useRef(null)
  const polygonRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDgvxihvJPXifWy6F1T3lY5uF2vjctF4XU"
  })

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])


  const onUnmount = useCallback(function callback(map) {
    setMap(null)

  }, [])

  const onPolygonUnmount = useCallback(function (polygon) {
    polygonRef.current = null;
  }, [])
  const handleDragMarkerEnd = useCallback((e) => {
    console.log("handleDragMarkerEnd e::", e)
    const lat = Math.round(e.latLng.lat() * 1000000) / 1000000
    const lng = Math.round(e.latLng.lng() * 1000000) / 1000000
    const position = { lat, lng, rads: latLngData?.rads }
    setLatLngData({ ...latLngData, ...position })
    // 
  }, [setLatLngData, latLngData])

  const handleDropPin = useCallback(() => {
    // if (latLngData) {
    //   setLatLngData({ lat: null, lng: null, rad: null })
    // } else {
    if(isPinInBound){
      setLatLngData({...latLngData,  lat: null, lng: null, rad: null })
      return
    }  

    if (!map) return
    console.log("latLngData::", latLngData)
    if (latLngData && latLngData.lat && latLngData.lng) {
      map.panTo({ lat: +latLngData.lat, lng: +latLngData.lng })
    } else {
      const lat = Math.round(map.getCenter().lat() * 1000000) / 1000000
      const lng = Math.round(map.getCenter().lng() * 1000000) / 1000000
      setLatLngData({ ...latLngData, lat, lng, rads: null })
    }
    // }
    // setMarkerPosition({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
  }, [map, setLatLngData, latLngData, isPinInBound])

  const handleDropArea = useCallback(() => {
    // console.log("handleDropArea map::", map)
    if(latLngData.points){
      setLatLngData({...latLngData, points: null})
      return
    }
    if (!map) return
    const lat = Math.round(map.getCenter().lat() * 1000000) / 1000000
    const lng = Math.round(map.getCenter().lng() * 1000000) / 1000000
    const bounds = map.getBounds()
    // console.log("handleDropArea lat::", lat, lng)
    // console.log("handleDropArea bounds::", bounds)
    // console.log("handleDropArea getNorthEast::", bounds.getNorthEast())
    // console.log("handleDropArea getSouthWest::", bounds.getSouthWest())
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const offsetLat = (ne.lat() - sw.lat()) / 10
    const offsetLng = (ne.lng() - sw.lng()) / 10
    const points = [
      { lat: lat + offsetLat, lng: lng + offsetLng },
      { lat: lat + offsetLat, lng: lng - offsetLng },
      { lat: lat - offsetLat, lng: lng - offsetLng },
      { lat: lat - offsetLat, lng: lng + offsetLng },
    ]
    setLatLngData({ ...latLngData, points: points })
  }, [map, setLatLngData, latLngData])

  const handleOpenGoogleMap = useCallback(() => {
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${LAEMCHABANG_SEA_PORT.lat},${LAEMCHABANG_SEA_PORT.lng}&destination=${latLngData.lat},${latLngData.Lng}`, "_blank")
  }, [latLngData])

  const handleLatLngBlur = useCallback((name) => (e) => {
    /**
     * latLngData is not state
     */
    const value = e.target.value
    console.log("handleLatLngBlur value::", value)
    setLatLngData({ ...latLngData, [name]: value ? +e.target.value : null })
  }, [latLngData, setLatLngData])

  const handleLatPaste = useCallback((e) => {
    const value = e.clipboardData.getData('Text')
    console.log("value ::", value)
    const latLngData = value.split(",")
    if (latLngData.length !== 2) {
      return
    }
    console.log("latLngData ::", latLngData)
    if (isNaN(latLngData[0].trim()) || isNaN(latLngData[1].trim())) {
      return
    }
    console.log("not NaN ")
    e.preventDefault()
    e.target.value = latLngData[0]
    lngRef.current.value = latLngData[1].trim()
    setLatLngData({ lat: +latLngData[0].trim(), lng: +latLngData[1].trim() })
  }, [setLatLngData])


  const onEdit = useCallback((e) => {
    console.log("onEdit e::", e.domEvent)
    if (e.domEvent?.button === 0) {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map(latLng => {
            return { lat: latLng.lat(), lng: latLng.lng() };
          });
        // setPath(nextPath);
        setLatLngData({
          ...latLngData, points: nextPath
        })
      }
    } else if (e.domEvent?.button === 2) {
      if (e.vertex) {
        const points = latLngData.points
        if (points && points.length > 3) {
          setLatLngData({
            ...latLngData, points: points.filter((vertex, index) => index !== e.vertex)
          })
        }
        // setPath((o) => {
        //   if(o.length <= 3){
        //     return o
        //   }
        //   return o.filter((vertex, index) => index !== e.vertex)
        // })
      }
    }

  }, [latLngData, setLatLngData])


  const onPolygonLoad = useCallback(polygon => {
    polygonRef.current = polygon;
  }, [])

  const calculateIsPinInBound = useCallback(() => {
    
    if (!isLoaded) {
      return
    }
    console.log("in calculateIsPinInBound latLngData::", latLngData)
    if (!latLngData || !latLngData.lat || !latLngData.lng) {
      setIsPinInBound(false)
      return 
    }
    if (!map) {
      setIsPinInBound(false)
      return 
    }
    const bounds = map.getBounds()
    console.log('bounds::', bounds)
    if(!bounds){
      return
    }
    const latLng = new window.google.maps.LatLng(latLngData.lat, latLngData.lng)
    setIsPinInBound(bounds.contains(latLng))
  }, [latLngData, map, isLoaded])

  const handleMapIdle = useCallback(() => { 
    calculateIsPinInBound()
  }, [calculateIsPinInBound])

  useEffect(() => {
    calculateIsPinInBound()
  }, [calculateIsPinInBound])
  console.log("latLngData::", latLngData)
  console.log("in googleMapWrapper initCenter::", initCenter)
  console.log("isPinInBound::", isPinInBound)

  return isLoaded ?
    <BoxFC height="100%" width="100%">
      <BoxFR width="100%">
        <BoxFR flex={1}>


          <Tooltip title={latLngData.points?"วาดพื้นที่": "ลบพื้นที่"} arrow>
            <Button variant="contained" sx={{ minWidth: 0 }} onClick={handleDropArea}>
              {latLngData.points?<BrowserNotSupportedRounded />: <CropSquareRoundedIcon />} 
            </Button>
          </Tooltip>
          <Tooltip title={isPinInBound?"ลบหมุด":"ปักหมุด"} arrow>
            <Button variant="contained" sx={{ minWidth: 0 }} onClick={handleDropPin}>
              {isPinInBound?<LocationOffRounded />: <PinDropRoundedIcon />}
            </Button>
          </Tooltip>

          <TextField variant="outlined" size="small"
            disabled={latLngData && latLngData.polys}
            label="ละติจูด"
            key={`lat_${latLngData?.lat}`}
            type="number"
            onPaste={handleLatPaste}
            onBlur={handleLatLngBlur("lat")}
            defaultValue={latLngData?.lat ?? ""} />
          <TextField variant="outlined" size="small"
            disabled={latLngData && latLngData.polys}
            label="ลองจิจูด"
            inputRef={lngRef}
            key={`lng_${latLngData?.lng}`}
            type="number"
            onBlur={handleLatLngBlur("lng")}
            defaultValue={latLngData?.lng ?? ""} />

          <Tooltip title="เปิด google map" arrow>
            <span>
              <Button variant="contained"
                onClick={handleOpenGoogleMap}
                sx={{ minWidth: 0, px: 1 }} disabled={latLngData ? false : true}>
                <ReplyRoundedIcon sx={{ transform: "scaleX(-1)" }} />
              </Button>
            </span>
          </Tooltip>
          <TextField variant="outlined" size="small"
            label="รัศมี"
            inputRef={lngRef}
            key={`rad_${latLngData?.rad}`}
            type="number"
            onBlur={handleLatLngBlur("rad")}
            defaultValue={latLngData?.rad ?? ""}
            inputProps={{ style: { textAlign: "right" } }}
            InputProps={{
              endAdornment: <InputAdornment position="end">เมตร</InputAdornment>,
            }}
            disabled={!latLngData || !latLngData.lat || !latLngData.lng} />
        </BoxFR>
      </BoxFR>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={modInitCenter}
        mapTypeId="satellite"
        zoom={defaultZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onIdle={handleMapIdle}
      >
        { /* Child components, such as markers, info windows, etc. */}
        {
          latLngData.lat && latLngData.lng &&
          <Marker draggable
            position={latLngData}
            onDragEnd={handleDragMarkerEnd} />
        }
        {
          latLngData.lat && latLngData.lng && latLngData.rad &&
          <Circle center={latLngData} radius={+latLngData.rad}
            options={{ strokeColor: "#ff0000", fillColor: "#ff0000", fillOpacity: 0.3, strokeWeight: 2 }} />
        }
        {
          latLngData.points &&
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={latLngData.points}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onPolygonLoad}
            onUnmount={onPolygonUnmount}
            options={{ strokeColor: "#ff0000", fillColor: "#ff0000", fillOpacity: 0.3, strokeWeight: 2 }}
          />
        }
      </GoogleMap>
    </BoxFC>
    : <></>
}

export default React.memo(GoogleMapWrapper)