import { Box, Button, Dialog, DialogContent, Divider, TextField, Typography, useMediaQuery } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { invoiceApiNm, invoiceColNm } from "constants/invoiceConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import dayjs from "dayjs"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { expenseColumns } from "./columns"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { numberFormat } from "utils"
import { blueGrey } from "@mui/material/colors"

const moneyProp = {
  inputProps: { style: { textAlign: "right" } },
  sx: { width: 120 },
}

const initInvoiceDailog = {
  InvNo: "",
  InvDte: "",
  Rmk: "",
  Total: "",
  WhtPr: "",
  PayAmnt: "",
}
const InvoiceDialog = ({ invId, dialogOpen, setDialogOpen, contNo }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initInvoiceDailog })
  const [chargeTable, setChargeTable] = useState([])
  const [advanceTable, setAdvanceTable] = useState([])
  const [invoiceFiles, setInvoiceFiles] = useState([])

  const w800 = useMediaQuery('(min-width:800px)');

  const textFieldProp = useCallback((name) => ({
    name: name,
    size: "small",
    label: invoiceColNm[name],
    value: dialogData[name],
  }), [dialogData])

  const modChargeTable = useMemo(() => {
    return addSumRow({
      dataTable: chargeTable,
      sumColNms: ["Total"],
      sumColTotal: "ExpNm"
    })
  }, [chargeTable])

  const modAdvanceTable = useMemo(() => {
    return addSumRow({
      dataTable: advanceTable,
      sumColNms: ["Total"],
      sumColTotal: "ExpNm"
    })
  }, [advanceTable])

  const generateFileBox = useMemo(() => (
    <BoxFC>
      <Typography variant="body2" fontWeight="bold">Files </Typography>
      <Box height={110} width={w800?440:"100%"} bgcolor={blueGrey[50]} mt={-2} p={1}>
        {invoiceFiles.map((file, index) => (
          <BoxFR key={file.InvFileId}>
            <Typography variant="body2" width={15}>{index + 1}.</Typography>
            <a href={`/file/invoice/${file.InvId}/${file.FileNm}`} target="_blank" rel="noreferrer">
              <Button size="small">
                <Typography variant="body2" >{file.Label}</Typography>
              </Button>
            </a>
          </BoxFR>
        ))}
      </Box>
    </BoxFC>
  ), [invoiceFiles, w800])

  const generateTotalBox = useMemo(() => (
    <BoxFC alignItems="flex-end" sx={{ gap: 1 }}>
      <TextField {...textFieldProp("Total")} {...moneyProp} label="Invoice Total" />
      <TextField {...textFieldProp("WhtPr")} {...moneyProp} label="Wht Total" />
      <TextField {...textFieldProp("PayAmnt")} {...moneyProp} label="Payment Total" />
    </BoxFC>
  ), [textFieldProp])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(invoiceApiNm.getInvoiceDetailForClient, { InvId: invId, ContNo: contNo }).then(value => {
        console.log("value.data::", value.data)
        if (value.data) {
          const { sumTotal, sumWhtPr } = value.data.Exps.reduce((prev, cur) => ({
            sumTotal: prev.sumTotal + +cur.Total,
            sumWhtPr: prev.sumWhtPr + +cur.WhtPr
          }), { sumTotal: 0, sumWhtPr: 0 })
          setDialogData({
            ...value.data,
            InvDte: dayjs(value.data.InvDte).format("YYYY-MM-DD"),
            Total: numberFormat(sumTotal),
            WhtPr: numberFormat(sumWhtPr),
            PayAmnt: numberFormat(sumTotal - sumWhtPr),
          })
          setChargeTable(value.data.Exps
            .filter(exp => exp.IsOwnRcpt)
            .map((exp, index) => ({ ...exp, No: index + 1, id: `${exp.ExpId}:${exp.UntPr}` })))

          setAdvanceTable(value.data.Exps
            .filter(exp => !exp.IsOwnRcpt)
            .map((exp, index) => ({ ...exp, No: index + 1, id: `${exp.ExpId}:${exp.UntPr}` })))

          setInvoiceFiles(value.data.Files)
        }
      })
    }
  }, [dialogOpen, ax, invId, contNo])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' fullScreen={!w800}

    // PaperProps={{
    //   style: {
    //     minHeight: '90%',
    //     maxHeight: '90%',
    //   }
    // }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`Invoice #${dialogData.InvNo}`}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} sx={{ gap: 1 }}>
          {
            contNo ? null :
              <BoxFC sx={{ gap: 1 }}>
                <BoxFR>
                  <TextField {...textFieldProp("InvNo")} label="Invoice No." />
                  <Box flex={1} />
                  <TextField {...textFieldProp("InvDte")} label="Invoice Date" />
                </BoxFR>
                <TextField {...textFieldProp("Rmk")} label="Remark" />
                <Divider />
              </BoxFC>
          }
          <Typography variant="body2" mt={1} fontWeight="bold">Charge</Typography>
          <Box height={150} mt={-1} sx={sxSumColorClass}>
            <DataGridCellExpand
              hideToolbar
              density="compact"
              hideFooter
              rows={modChargeTable}
              columns={expenseColumns}
              getRowClassName={getSumColorClassName}
            />
          </Box>
          <Divider />
          <Typography variant="body2" mt={1} fontWeight="bold">Advance</Typography>
          <Box height={200} mt={-1} sx={sxSumColorClass}>
            <DataGridCellExpand
              density="compact"
              hideFooter
              rows={modAdvanceTable}
              columns={expenseColumns}
              getRowClassName={getSumColorClassName}
            />
          </Box>
          <Divider />
          {w800 ? (
            <BoxFR>
              {contNo ? <Box width={440} /> : generateFileBox}
              {generateTotalBox}
            </BoxFR>
          ) : (
            <BoxFC>
              {generateTotalBox}
              {contNo ? null : generateFileBox}
              </BoxFC>
          )}

        </BoxFC>
      </DialogContent >
      <DialogFooter okText={""} cancelText={"ปิด"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog >
  )
}

export default React.memo(InvoiceDialog)