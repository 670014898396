import {  Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useCallback, useEffect, useState } from "react"

const PlanDialog = ({ title, oldText, oldVersion, dialogOpen, setDialogOpen, onFinish }) => {

  const [text, setText] = useState("")
  const [version, setVersion] = useState("")
  
  const handleDialogOK = useCallback(() => {
    onFinish(text, version)
    setDialogOpen(false)
  }, [text, onFinish, setDialogOpen, version])

  useEffect(() => {
    if (dialogOpen) {
      if(oldText) setText(oldText)
      if(oldVersion) setVersion(oldVersion)
    } else {
      setText("")
      setVersion("")
    } 
  }, [dialogOpen, oldText, oldVersion])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <TextField 
            autoFocus size="small" fullWidth label="ชื่อแบบฟอร์ม"
            value={text} 
            onChange={(e)=>{setText(e.target.value)}} />
          <TextField 
            size="small" fullWidth label="เวอร์ชั่น"
            value={version} 
            onChange={(e)=>{setVersion(e.target.value)}} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(PlanDialog)