import { printJobOrder } from "branch/functions/printJobOrder"
import { jobOrderApiNm } from "constants/jobOrderConstant"

export const getDataAndPrintJobOrder = (ax, msData, jobOrdIds, fName) => {
  const printDataArr = []
    ax.post(jobOrderApiNm.getDataForPrint, { JobOrdIds: jobOrdIds }).then(value => {
      if(value.data){
        const {jobOrders, shipments, jobs} = value.data
        
        for (const sh of shipments) {
          sh.Job = jobs.find(item => item.JobId === sh.JobId)
        }
        for (const jo of jobOrders) {
          jo.Shipment = shipments.find(item => item.ShpmId === jo.ShpmId)
          jo.JobNo = jo.Shipment.JobNo
        }
        for (const jobOrderData of jobOrders) {
          printDataArr.push({ jobOrderData, shipmentData: jobOrderData.Shipment, jobOrdIds: [jobOrderData.JobOrdId], fName: fName })
        }
        printJobOrder({ printDataArr, msData })
      }
    })
}