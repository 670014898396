import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { initFilterData } from "./initData";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import { branchApiNm } from "../ExtraMainLayoutConstant";
// import { addIdForDataGrid } from "utils";
import { addSumRow, sxSumColorClass } from "utils/highlightSumColor";
import { columns } from "./columns";
import { Box, Button, Typography } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { blue } from "@mui/material/colors";
import DailyJobDialogV2 from "pages/Menu1/DailyJob/DailyJobDialogV2";
import { printReport } from "./printReport";
import dayjs from "dayjs";
import { numberFormatNoFlotingPoint } from "utils";

let selectedJobOrdId = null

const TruckIncomeSummary = () => {
  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [dataTable, setDataTable] = useState([])
  const [dailyJobDialogOpen, setDailyJobDialogOpen] = useState(false)

  const modDataTable = useMemo(() => {
    const result = addSumRow({
      dataTable: dataTable,
      sumColNms: ["NumJob", "SumTrnsUntPr"],
      sumColTotal: "TukCd"
    })
    const sumRow = result.find(row => row.id === 0)
    if(sumRow){
      sumRow.AvgTrnsUntPr = +sumRow.SumTrnsUntPr / +sumRow.NumJob
    }
    return result
  }, [dataTable])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getTruckIncomeSummary, filter).then(value => {
      const result = []
      let no = 0
      for (const data of value.data) {
        no++
        let isFirst = true
        if (!data.JobOrders) {
          result.push({
            id: data.TukId,
            No: no,
            TukCd: data.TukCd,
            LicNm: data.LicNm,
            Model: data.Model,
            DrvNm: data.DrvNm,
            TukRmk: data.TukRmk,
            Dscp: data.Dscp,
            NumJob: data.NumJob,
            SumTrnsUntPr: data.SumTrnsUntPr,
            AvgTrnsUntPr: data.AvgTrnsUntPr,
          })
          continue
        }
        for (const jo of data.JobOrders) {
          const row = {
            id: jo.JobOrdId,
            ...jo,
            NumJob: "",
            TukCd: "",
          }
          if (isFirst) {
            row.No = no
            row.TukCd = data.TukCd
            row.LicNm = data.LicNm
            row.Model = data.Model
            row.DrvNm = data.DrvNm
            row.TukRmk = data.TukRmk
            row.Dscp = data.Dscp
            row.NumJob = data.NumJob
            row.SumTrnsUntPr = data.SumTrnsUntPr
            row.AvgTrnsUntPr = data.AvgTrnsUntPr
            isFirst = false
          }
          result.push(row)
        }
      }
      console.log("result", result)
      setDataTable(result)
    })
  }, [ax])

  const setSelectDate = useCallback((start, end) => {
    setFilterData(o => ({
      ...o,
      AptTmSt: start,
      AptTmEn: end
    }))
  }, [])

  const handleCusComboChange = useCallback((id) => {
    setFilterData(o => ({ ...o, CusId: id, JobId: null }))
    if (!id) {
      setJobCombo(msData.jobCombo)
    } else {
      setJobCombo(msData.jobCombo.filter(job => job.refId === id))
    }
  }, [msData.jobCombo])

  const handleSearchButtonClick = useCallback(() => {
    console.log("filterData", filterData)
    getData(filterData)
  }, [getData, filterData])

  const handleClearButtonClick = useCallback(() => { }, [])

  const handlePrintReport = useCallback(() => {
    const printReportData = modDataTable.map(row =>( {
      ...row,
      Loc: `${row.TakePlc||""}-${row.Loc||""}-${row.RtnPlc||""}`,
      TukRmk: `${row.TukRmk||""}${row.TukRmk? `, ${row.Dscp||""}`: row.Dscp||""}`,
      TrnsUntPr: +row.TrnsUntPr? numberFormatNoFlotingPoint(+row.TrnsUntPr): "",
      SumTrnsUntPr: +row.SumTrnsUntPr? numberFormatNoFlotingPoint(+row.SumTrnsUntPr): "",
      AvgTrnsUntPr:+row.AvgTrnsUntPr?  numberFormatNoFlotingPoint(+row.AvgTrnsUntPr): "",
    }))
    const sumRow = printReportData.find(row=>row.id ===0)
    sumRow.TrnsUntPr = ""
    console.log("sumRow::", sumRow)
    printReport({dataTable: printReportData, aptTm: dayjs(filterData.AptTmSt).format("DD/MM/YYYY"), msData})
  }, [filterData, msData, modDataTable])

  const onDailyJobDialogFinish = useCallback(() => {
    getData(filterData)
  }, [getData, filterData])

  const handleOpenDailyJobDialog = useCallback((jobOrdId) => { 
    selectedJobOrdId = jobOrdId
    setDailyJobDialogOpen(true)
  }, [])
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height="100%" sx={{ position: "relative" }}>
    <BoxFR sx={{ position: "absolute", top: 0, left: 0 }}>
      <Button variant="contained" onClick={handlePrintReport}>พิมพ์ Report</Button>
    </BoxFR>
      <BoxFR width="100%" sx={{ justifyContent: "center" }}>
        <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่วิ่งงาน"
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
          getData={handleSearchButtonClick}
        />
        <ComboBox sx={{ width: 180 }} options={msData.cusCombo} label="ลูกค้า"
          selectedId={filterData.CusId}
          setSelectedId={handleCusComboChange}
        />
        <ComboBox sx={{ width: 180 }} options={jobCombo} label="งาน"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <SearchButton onClick={handleSearchButtonClick} />
        <ClearButton onClick={() => handleClearButtonClick()} />
      </BoxFR>
      <Box flex={1} sx={sxSumColorClass}>
        <DataGridCellExpand
          density="compact"
          hideFooter
          disableSelectionOnClick
          rows={modDataTable}
          columns={columns(handleOpenDailyJobDialog)}
          getRowClassName={(params) => [-1, 0].includes(params.id) && "total"}
        />
      </Box>
      <Typography variant="caption" sx={{ color: blue[700] }}>
        <strong>หมายเหตุ: </strong>C=Charge, A=Advance, T=Total
      </Typography>
      
      <DailyJobDialogV2
        dialogOpen={dailyJobDialogOpen}
        setDialogOpen={setDailyJobDialogOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDailyJobDialogFinish}
        lastFilter={null} />
    </BoxFC>
  );
}

export default TruckIncomeSummary;