import { ImageRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { jobOrderColNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";
import { getPOIPlace } from "utils/getPOIPlace";


export const columns = (handleImageClick) => [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "Loc",
    headerName: "Location",
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 160,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 130,
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 130,
  },
  {
    field: "AptTm",
    headerName: "Appoint Time",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "ArvTm",
    headerName: "Arrive",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "LevTm",
    headerName: "Leave",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "TakePlc",
    headerName: "CY",
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "RtnPlc",
    headerName: "Return",
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
  },
  {
    field: "RtnDteSt",
    headerName: "First Return",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "RtnDteEn",
    headerName: "Cut-Off",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "ContSizeFull",
    headerName: "Size",
    width: 60,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 130,
  },
  {
    field: "SealNo",
    headerName: "Seal No.",
    width: 100,
  },
  {
    field: "Port",
    headerName: "Port",
    width: 100,
  },
  {
    field: "NumFile",
    headerName: "Images",
    width: 60,
    renderCell: (params) => (
      <IconButton onClick={params.value ? handleImageClick(params.id) : null}>
        <ImageRounded color={params.value ? "primary" : "inherit"} />
      </IconButton>
    )
  },
  {
    field: "TareWt",
    headerName: "Tare Wt.",
    width: 70,
  },
  // {
  //   field: "TuckNo",
  //   headerName: "Truck",
  //   width: 90,
  // },
  {
    field: "DrvNm",
    headerName: "Driver",
    width: 80,
  },
  {
    field: "TukCd",
    headerName: "Truck",
    width: 80,
  },
  {
    field: "Status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "FinDte",
    headerName: "Complted At",
    width: 100,
    type: "dateTime",
    align: "center",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "Rmk",
    headerName: "Remark",
    flex: 1,
    minWidth: 180,
  }
]