import { Box, Button, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import ComboBox from "components/ComboBox";
import { jobOrderColNm } from 'constants/jobOrderConstant';
import DateTimeSelect from 'components/DateTimeSelect/DateTimeSelect';
import { branchApiNm } from '../ExtraMainLayoutConstant';
import DataTableDialog from "./DataTableDialog";
import { invoiceColNm } from "constants/invoiceConstant";

// const initFilterData = {
//   CusId: null,
//   AptTmSt: dayjs().add(-1, "month").format("YYYY-MM-01 00:00:00"),
//   AptTmEn: dayjs().add(-1, "month").endOf("month").format("YYYY-MM-DD 23:59:59.997")
// }

const initFilterData = {
  CusId: null,
  AptTmSt: dayjs().format("YYYY-MM-DD 00:00:00"),
  AptTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
  InvDteSt: null,
  InvDteEn: null
}
let mode = "INV"
let dialogDataTable = []
const JobOrderAllDetail = () =>{

  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState(initFilterData)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  
  const getData = useCallback((filterData) => {
    
    ax.post(branchApiNm.getJobOrderAllDetail, filterData).then(value => {
      if (value.data) {
        for(const row of value.data){
          if(row.Exps && row.Exps.length > 0){
            row.Trns = row.Exps.find(exp=>exp.ExpId === 1)?.Total  || 0
            row.LiftF = row.Exps.find(exp=>exp.ExpId === 2)?.Total  || 0
            row.LiftE = row.Exps.find(exp=>exp.ExpId === 3)?.Total  || 0
            row.PortPass = row.Exps.find(exp=>exp.ExpId === 4)?.Total  || 0
            row.DeptPass = row.Exps.find(exp=>exp.ExpId === 5)?.Total  || 0
            row.OthCusRcpt = row.Exps.reduce((prev, cur)=>{
              if(![2,3,4,5].includes(cur.ExpId) && cur.IsOwnRcpt === 0){
                return prev + cur.Total
              } else {
                return prev
              }
            } , 0)
          }
        }
        setDataTable(addIdForDataGrid(value.data, "JobOrdId"))
      }
    })
  }, [ax])

  const setSelectDate = useCallback((st, en)=>{
    setFilterData(o=>({...o, AptTmSt: st, AptTmEn: en}))
  }, [])

  const setSelectInvDate = useCallback((st, en)=>{
    setFilterData(o=>({...o, InvDteSt: st, InvDteEn: en}))
  }, [])

  const comboProp = useMemo(() => ({
    options: msData.cusCombo,
    label: "ลูกค้า",
    sx: { width: 200},
    selectedId: filterData.CusId,
    setSelectedId: (id) => setFilterData(o=>({...o, CusId: id}))
  }), [filterData.CusId, msData.cusCombo])

  // const handleShowDialogInv = useCallback(()=>{
  //   mode = "INV"
  //   dialogDataTable = dataTable.map(item=>({...item}))
  //   for(const row of dialogDataTable){
  //     if( +row.Total > 5000 ) {
  //       row.Total = +row.Total + 1000
  //     }
  //   }
  //   setDialogOpen(true)
  // }, [dataTable])
  
  // const handleShowDialogBN = useCallback(()=>{
  //   mode = "BN"
  //   const cloneDataTable = dataTable.map(item=>({...item}))
  //   sortObj(cloneDataTable, "InvNo")
  //   dialogDataTable = []
  //   let lastInvNo = ""
  //   for(const row of cloneDataTable){
  //     if( +row.Total > 5000 ) {
  //       row.Total = +row.Total + 1000
  //     }
  //     if(lastInvNo !== row.InvNo){
  //       lastInvNo = row.InvNo
  //       dialogDataTable.push(row)
  //       row.Qty = 1
  //     } else {
  //       const lastRow = dialogDataTable[dialogDataTable.length - 1]
  //       lastRow.Qty += 1
  //       lastRow.Total += +row.Total
  //       lastRow.WhtPr += +row.WhtPr
  //       lastRow.ContSizeFull = lastRow.ContSizeFull.indexOf(row.ContSizeFull) === -1 ? 
  //         `${lastRow.ContSizeFull}, ${row.ContSizeFull}` : lastRow.ContSizeFull
  //     }
  //   }

  //   setDialogOpen(true)
  // }, [dataTable])
  
  useEffect(()=>{
    getData(initFilterData)
  }, [getData])
  return (
    <BoxFC p={2} height="100%">
      <BoxFR>
        <Typography variant="h6">รายละเอียดใบงาน </Typography>
        <DateTimeSelect
          sx={{width:180}}
          label={jobOrderColNm.AptTm}
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect
          sx={{width:180}}
          label={invoiceColNm.InvDte}
          start={filterData.InvDteSt}
          end={filterData.InvDteEn}
          setSelectDate={setSelectInvDate}
          getData={() => { getData(filterData) }}
        />
        <ComboBox {...comboProp}/>
        <Button variant="contained" onClick={() => getData(filterData)}>
          <SearchRoundedIcon mr={1}/> ค้นหา
        </Button>
        <Box flex={1}/>
        {/* <Button variant="contained" onClick={handleShowDialogInv}>จัดเรียงข้อมูล แจ้งหนี้</Button>
        <Button variant="contained" onClick={handleShowDialogBN}>จัดเรียงข้อมูล วางบิล</Button> */}
      </BoxFR>
      <Box width="100%" flexGrow={1} >
        <DataGridCellExpand
          disableSelectionOnClick={false}
          hideFooter
          rows={dataTable}
          columns={columns} />
      </Box>
      <DataTableDialog 
      dialogOpen={dialogOpen} 
      setDialogOpen={setDialogOpen}
      mode={mode}
      dataTable={dialogDataTable}/>
    </BoxFC>
  )
}

export default JobOrderAllDetail