import React, { useCallback, useMemo, useState, useContext } from "react";
import { Box, Dialog, DialogContent, Paper } from "@mui/material"
import ModAccName from "components/ModAccName";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { initDialogData, shipmentApiNm } from "constants/shipmentConstant";
import { validate } from "utils";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import FileUpload from "components/FileUplaod/FileUpload";
import { coreApiNm, FOLDER_SHIPMENT_FILE } from "constants/serverConstant";
import dayjs from "dayjs";
import { initFileUploadData } from "constants/fileUploadConstant";
import ShipmentData from "components/ShipmentData";
// import MemoComponent from "components/MemoComponent";
import { useEffect } from "react";
import { BoxFC } from "components/BoxCustom";
import PaperComponent from "components/PaperComponent";
import CheckobxFormControl from "components/CheckobxFormControl";
import { alertConfirmDelete, alertWarning } from "components/Alert";
import { saveFile } from "components/FileUplaod/saveFile";
import { blueGrey } from "@mui/material/colors";

let firstData = null;
const ShipmentDialog = ({ state, fn }) => {
  const { dialogOpen, selectedId, lastFilter, oneYearVsslData = [], oneYearPortData = [] } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const { ax, msData } = useContext(GlobalStateContext);
  const [fileUploadData, setFileUploadData] = useState({ ...initFileUploadData() })
  const [isShowShipmentData, setIsShowShipmentData] = useState(true)
  const [memoData, setMemoData] = useState([])
  const [isClearData, setIsClearData] = useState(true)

  const modifyData = useCallback((dialogData) => {
    const serverFiles = dialogData.Files.map(item => ({
      id: item.ShpmFileId,
      fileNm: item.FileNm,
      label: item.Label,
      oLabel: item.Label,
      ShpmId: selectedId,
      isEdit: false,
      file: null,
      imgLink: `${window.location.origin}/${FOLDER_SHIPMENT_FILE}${item.ShpmId}/${item.FileNm}`,
    }))
    setFileUploadData({ ...initFileUploadData(), serverFiles })
    return dialogData
  }, [selectedId])


  initDialogData.RecvDocDte = dayjs();

  const validation = useMemo(() => ({
    RecvDocDte: dialogData.RecvDocDte == null || !dayjs(dialogData.RecvDocDte).isValid(),
    JobNo: dialogData.JobNo === "",
    ShpmTypId: dialogData.ShpmTypId === "" || dialogData.ShpmTypId === null,
    JobId: dialogData.JobId === null,
    TakeDteSt: dialogData.TakeDteSt != null && !dayjs(dialogData.TakeDteSt).isValid(),
    TakeDteEn: dialogData.TakeDteEn != null && !dayjs(dialogData.TakeDteEn).isValid(),
    RtnDteSt: dialogData.RtnDteSt != null && !dayjs(dialogData.RtnDteSt).isValid(),
    RtnDteEn: dialogData.RtnDteEn != null && !dayjs(dialogData.RtnDteEn).isValid(),
  }), [dialogData])

  // const getMemoArgs = useMemo(() => ({
  //   ShpmId: dialogData.ShpmId
  // }), [dialogData.ShpmId])

  const deleteShipment = useCallback((shpmId) => {
    alertConfirmDelete(() => {
      ax.post(shipmentApiNm.deleteShipment, { ShpmId: shpmId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          if (onFinish) {
            onFinish(value.data);
          }

          setDialogOpen(false);
        }
      })
    })
  }, [ax, lastFilter, onFinish, setDialogOpen])

  const insertUpdateShipment = useCallback(async (initShpmId, dialogData, fileUploadData, setFileUploadData, memData, isClearData) => {
    const isInsert = initShpmId === 0;
    for (const file of fileUploadData.serverFiles) {
      if (file.isEdit) {
        alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
        return;
      }
    }
    for (const file of fileUploadData.addedFiles) {
      if (file.isEdit) {
        alertWarning("กรุณายืนยันชื่อไฟล์ก่อน");
        return;
      }
    }
    let value = null
    let shpmId = initShpmId
    if (dialogData) {
      dialogData.getArgs = lastFilter;
      value = await ax.post(isInsert ? shipmentApiNm.insertShipment : shipmentApiNm.updateShipment, dialogData);
      if (isInsert) shpmId = value.data
      if (!value.data) {
        return
      }
    }

    const refreshFromFile = fileUploadData.addedFiles.length > 0 || fileUploadData.deletedFiles.length > 0

    await saveFile({
      ax, alertWarning, fileUploadData, setFileUploadData,
      apiUpdateFileName: shipmentApiNm.updateFileName,
      apiDeleteFile: shipmentApiNm.deleteFile,
      apiAddFile: shipmentApiNm.uploadFile,
      key: "ShpmFileId",
      refKey: "ShpmId",
      refValue: shpmId
    })

    for (const data of memData) {
      if (isInsert) {
        data.RefId = shpmId
      }
      if (data.MemId > 0) {
        await ax.post(coreApiNm.updateMemo, data)
      } else {
        await ax.post(coreApiNm.insertMemo, data)
      }
    }


    if (onFinish) {

      if (isInsert) {
        onFinish(null)
      } else if (dialogData) {
        onFinish(value.data)
      } else if (memData && memData.length > 0) {
        onFinish(null)
      } else if (refreshFromFile) {
        onFinish(null)
      }
    }

    if (isClearData) {
      setDialogOpen(false);
    } else {
      setDialogData(o => ({ ...o, JobNo: "" }))
    }
  }, [ax, lastFilter, onFinish, setDialogOpen])

  const handleDialogOK = useCallback(() => {
    if (!validate(validation)) return;
    let postDialogData = null;
    if (JSON.stringify(dialogData) !== JSON.stringify(firstData)) {
      postDialogData = { ...dialogData }
    }
    insertUpdateShipment(dialogData.ShpmId, postDialogData, fileUploadData, setFileUploadData, memoData, isClearData)
  }, [validation, dialogData, fileUploadData, memoData, insertUpdateShipment, isClearData])

  useEffect(() => {
    if (dialogOpen) {
      setFileUploadData(JSON.parse(JSON.stringify(initFileUploadData())))
      if (selectedId > 0) {
        ax.post(shipmentApiNm.getShipment, { ShpmId: selectedId }).then(value => {
          let data = modifyData(value.data[0])
          setDialogData(data)
          firstData = { ...data };
        })
      } else {
        setDialogData(initDialogData)
        firstData = { ...initDialogData }
      }
    }
  }, [dialogOpen, selectedId, ax, modifyData])
  //useDialogOpen(dialogOpen, selectedId, shipmentApiNm.getShipment, "ShpmId", setDialogData, initDialogData, modifyData)

  useEffect(() => {
    if (dialogOpen === false) {
      setIsShowShipmentData(true)
      setMemoData([])
      setIsClearData(true)
    }
  }, [dialogOpen])

  // console.log("oneYearPortData::", oneYearPortData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="lg" >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId === 0 ? "เพิ่มใบแจ้งลาก" : "แก้ไขใบแจ้งลาก"}
        onDeleteClick={selectedId > 0 ? () => deleteShipment(selectedId) : null}
      >
      </DialogHeader>
      <DialogContent sx={{ display: "flex", justifyContent: "center", gap: 2, bgcolor: blueGrey[50] }}>
        <Paper sx={{ p: 2, mt: 2 }}>

          <Box display="flex" flexDirection="row" mt={2} gap={4} flex={1} height={700} justifyContent="center">
            <BoxFC mt={-2}>
              <ShipmentData shipmentData={dialogData}
                setShipmentData={setDialogData}
                msData={msData}
                disabled={false}
                hideModAcc={true}
                validation={validation}
                oneYearVsslData={oneYearVsslData}
                oneYearPortData={oneYearPortData} />
              {
                dialogData.ShpmId > 0 ?
                  <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} /> :
                  <CheckobxFormControl label="ล้างข้อมูลหลังจากเพิ่มใบแจ้งลาก" checked={isClearData} onChange={(e) => setIsClearData(e.target.checked)} />
              }
            </BoxFC>

            <FileUpload
              width={300}
              options={msData.serverData.PreText.ShipmentFiles || []}
              fileUploadData={fileUploadData}
              setFileUploadData={setFileUploadData}
            />

            {/* <MemoComponent
            getArgs={dialogData.ShpmId > 0 && getMemoArgs}
            memTyp="SH"
            getApiNm={shipmentApiNm.getShipmentMemo}
            refId={dialogData.ShpmId}
            setMemoData={setMemoData} /> */}
          </Box>
        </Paper>
      </DialogContent>
      {isShowShipmentData &&
        <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
          handleDialogClose={() => setDialogOpen(false)}
          handleDialogOk={handleDialogOK} />
      }
    </Dialog>
  )
}

export default ShipmentDialog