import {  Typography } from "@mui/material";
import { BoxFC,  } from "components/BoxCustom";
import React, { useCallback } from "react";
import {  moveRowTo } from "utils";
import { Box } from "@mui/system";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { columns } from "./columns";

const DailyTableColWidthContent = ({ dailyTableColWidth, setDailyTableColWidth }) => {

  const handleChangeCol = useCallback((id, field, value) => {
    console.log("handleChangeCol::", id, field, value)
    setDailyTableColWidth(o => {
      const result = [...o]
      const row = result.find(r => r.id === id)
      console.log("row::", row)
      row[field] = value
      console.log("result::", result)
      return result
    })
  }, [setDailyTableColWidth])

  const handleRowOrderChange = useCallback((params) => {
    console.log(params)
    setDailyTableColWidth(o => moveRowTo(o, params.oldIndex, params.targetIndex).map((row, index) => ({ ...row, order: index })))
  }, [setDailyTableColWidth])


  const handleEditCommit = useCallback((params,  e) => {
    // console.log("params::", params)
    // console.log("e::", e)
    if (params.field === "width") {
      handleChangeCol(params.id, params.field, params.value)
    }
  }, [handleChangeCol])

  console.log("dailyTableColWidth::", dailyTableColWidth)
  return (
    <BoxFC height="100%" sx={{ gap: 0 }}>
      <Typography variant="h6">ตารางงานประจำวัน</Typography>
      <Box flex={1} width={550}>
        <DataGridCellExpand
          disableSelectionOnClick
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          hideFooter
          hideToolbar
          rows={dailyTableColWidth}
          columns={columns(handleChangeCol)}
          onCellEditCommit={handleEditCommit}
        />
      </Box>
      {/* <BoxFC sx={{ gap: 1, overflow: "auto" }} pl={2} pt={2}>
        {dailyTableColWidth.map((item, index) => (
          <BoxFR key={item.field}>
            <Typography sx={{ width: 120 }}>{item.headerName}</Typography>
            <TextField label="ความกว้าง" type="number" variant="outlined" size="small" sx={{ width: 100 }}
              inputProps={{ style: { textAlign: "center" } }}
              value={item.width}
              onChange={(e) => setDailyTableColWidth(o => {
                item.width = e.target.value
                return [...o]
              })} />
            <SwitchFormControl label={item.visible ? "แสดง" : "ซ่อน"}
              sx={{width:100}}
              checked={item.visible}
              onChange={(e) => setDailyTableColWidth(o => {
                item.visible = e.target.checked
                return [...o]
              })} />
            <TextField variant="outlined" size="small" sx={{width:90}} select value={item.colClr || ""} 
              onChange={e=> setDailyTableColWidth(o=>{ 
                item.colClr = e.target.value || null
                return [...o]
              })}>
              <MenuItem sx={menuItemSX} value={null}><Box sx={{...boxClrSX, bgcolor: "white"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="whitesmoke-column-color"><Box sx={{...boxClrSX, bgcolor: "whitesmoke"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="LavenderBlush-column-color"><Box sx={{...boxClrSX, bgcolor: "LavenderBlush"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Beige-column-color"><Box sx={{...boxClrSX, bgcolor: "Beige"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Azure-column-color"><Box sx={{...boxClrSX, bgcolor: "Azure"}}/></MenuItem>
              <MenuItem sx={menuItemSX} value="Honeydew-column-color"><Box sx={{...boxClrSX, bgcolor: "Honeydew"}}/></MenuItem>
            </TextField>
            <IconButton sx={{ ml: 1 }} onClick={() => setDailyTableColWidth(o => moveRow(o, index, -1))}><ArrowUpwardRoundedIcon /></IconButton>
            <IconButton sx={{ ml: -3 }} onClick={() => setDailyTableColWidth(o => moveRow(o, index, 1))}><ArrowDownwardRoundedIcon /></IconButton>
          </BoxFR>
        ))}
      </BoxFC> */}
    </BoxFC>
  )
}

export default React.memo(DailyTableColWidthContent)