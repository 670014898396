import { Box, Dialog, DialogContent } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React from "react"
import { bnColumns, invColumns } from "./columns"

const DataTableDialog = ({ dataTable, mode, dialogOpen, setDialogOpen }) => {

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={mode === "INV" ? "รายการใบแจ้งหนี้" : "รายการใบวางบิล"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} height={900}>

          <Box width="100%" flexGrow={1} >
            <DataGridCellExpand
              disableSelectionOnClick={false}
              hideFooter
              rows={dataTable}
              columns={mode==="INV"? invColumns: bnColumns} />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default DataTableDialog