import { jobOrderColNm } from "constants/jobOrderConstant"
import { shipmentColNm } from "constants/shipmentConstant"
const defaultDailyTableColWidth =  [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    visible: true,
    order: 1,
    colClr: null,
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
    visible: true,
    order: 2,
    colClr: null,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 120,
    visible: true,
    order: 3,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 120,
    visible: true,
    order: 4,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "CusSNm",
    headerName: jobOrderColNm.CusSNm,
    width: 120,
    visible: true,
    order: 5,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "ShpmTypAbbr",
    headerName: jobOrderColNm.ShpmTypAbbr,
    width: 100,
    visible: true,
    order: 6,
    colClr: null,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    visible: true,
    order: 7,
    colClr: "Azure-column-color",
  },
  {
    field: "TakePlc",
    headerName: jobOrderColNm.TakePlc,
    width: 90,
    visible: true,
    order: 8,
    colClr: null,
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    width: 120,
    visible: true,
    order: 9,
    colClr: null,
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    width: 90,
    visible: true,
    order: 10,
    colClr: null,
  },
  {
    field: "ContStses",
    headerName: "พนักงานขับรถ",
    width: 340,
    visible: true,
    order: 11,
    colClr: null,
  },
  {
    field: "PlnTxt",
    headerName: jobOrderColNm.PlnTxt,
    width: 300,
    visible: true,
    order: 12,
    colClr: null,
  },
  {
    field: "IsRdyTake",
    headerName: "สถานะ",
    width: 70,
    visible: true,
    order: 13,
    colClr: null,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 80,
    visible: true,
    order: 14,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 130,
    visible: true,
    order: 15,
    colClr: null,
  },
  {
    field: "SealNo",
    headerName: "ซีล",
    width: 100,
    visible: true,
    order: 16,
    colClr: null,
  },
  {
    field: "ISOCd",
    headerName: jobOrderColNm.ISOCd,
    width: 90,
    visible: true,
    order: 17,
    colClr: null,
  },
  {
    field: "FullWt",
    headerName: jobOrderColNm.FullWt,
    width: 90,
    visible: true,
    order: 18,
    colClr: null,
  },
  {
    field: "TareWt",
    headerName: jobOrderColNm.TareWt,
    width: 90,
    visible: true,
    order: 19,
    colClr: null,
  },
  {
    field: "NumFile",
    headerName: "รูปภาพ",
    width: 70,
    visible: true,
    order: 20,
    colClr: null,
  },
  {
    field: "TlrTyp",
    headerName: jobOrderColNm.TlrTyp,
    width: 90,
    visible: true,
    order: 21,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "TakeDteSt",
    headerName: jobOrderColNm.TakeDteSt,
    width: 100,
    visible: true,
    order: 22,
    colClr: null,
  },
  {
    field: "TakeDteEn",
    headerName: jobOrderColNm.TakeDteEn,
    width: 100,
    visible: true,
    order: 23,
    colClr: null,
  },
  {
    field: "RtnDteSt",
    headerName: jobOrderColNm.RtnDteSt,
    width: 100,
    visible: true,
    order: 24,
    colClr: null,
  },
  {
    field: "RtnDteEn",
    headerName: jobOrderColNm.RtnDteEn,
    width: 100,
    visible: true,
    order: 25,
    colClr: null,
  },
  
  {
    field: "TakeArvTm",
    headerName: jobOrderColNm.TakeArvTm,
    width: 100,
    visible: true,
    order: 26,
    colClr: null,
  },
  {
    field: "TakeLevTm",
    headerName: jobOrderColNm.TakeLevTm,
    width: 100,
    visible: true,
    order: 27,
    colClr: null,
  },
  {
    field: "ArvTm",
    headerName: jobOrderColNm.ArvTm,
    width: 100,
    visible: true,
    order: 28,
    colClr: null,
  },
  {
    field: "LevTm",
    headerName: jobOrderColNm.LevTm,
    width: 100,
    visible: true,
    order: 29,
    colClr: null,
  },
  {
    field: "RtnArvTm",
    headerName: jobOrderColNm.RtnArvTm,
    width: 100,
    visible: true,
    order: 30,
    colClr: null,
  },
  {
    field: "RtnLevTm",
    headerName: jobOrderColNm.RtnLevTm,
    width: 100,
    visible: true,
    order: 31,
    colClr: null,
  },
  {
    field: "FinDte",
    headerName: jobOrderColNm.FinDte,
    width: 100,
    visible: true,
    order: 32,
    colClr: null,
  },
  {
    field: "Agnt",
    headerName: shipmentColNm.Agnt,
    width: 100,
    visible: true,
    order: 33,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "Vssl",
    headerName: shipmentColNm.Vssl,
    width: 100,
    visible: true,
    order: 34,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "Voy",
    headerName: shipmentColNm.Voy,
    width: 80,
    visible: true,
    order: 35,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "Port",
    headerName: shipmentColNm.Port,
    width: 80,
    visible: true,
    order: 36,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "JobSNm",
    headerName: jobOrderColNm.JobSNm,
    width: 120,
    visible: true,
    order: 37,
    colClr: "whitesmoke-column-color",
  },
  {
    field: "Rmk",
    headerName: jobOrderColNm.Rmk,
    width: 300,
    visible: true,
    order: 38,
    colClr: null,
  },
  {
    field: "Memo",
    headerName: jobOrderColNm.Memo,
    width: 300,
    visible: true,
    order: 39,
    colClr: null,
  },
  {
    field: "JoRef1",
    headerName: jobOrderColNm.JoRef1,
    width: 100,
    visible: false,
    order: 40,
    colClr: null,
  },
  {
    field: "JoRef2",
    headerName: jobOrderColNm.JoRef2,
    width: 100,
    visible: false,
    order: 41,
    colClr: null,
  },
  {
    field: "ShRef1",
    headerName: jobOrderColNm.ShRef1,
    width: 100,
    visible: false,
    order: 42,
    colClr: null,
  },
  {
    field: "ShRef2",
    headerName: jobOrderColNm.ShRef2,
    width: 100,
    visible: false,
    order: 43,
    colClr: null,
  },
  {
    field: "TagNm",
    headerName: jobOrderColNm.TagNm,
    width: 100,
    visible: true,
    order: 44,
    colClr: null,
  },
  {
    field: "DXAmnt",
    headerName: jobOrderColNm.DXAmnt,
    width: 100,
    visible: false,
    order: 45,
    colClr: null,
  },
  {
    field: "DXNName",
    headerName: "พขร.(เงิน)",
    width: 120,
    visible: false,
    order: 46,
    colClr: null,
  },
]

export default defaultDailyTableColWidth